
import { Component, Vue } from 'vue-property-decorator';
import OsAppusersOverview from '@/components/shared/appusers/OsAppusersOverview.vue';
import OsInternalUsersByCustomerOverview from '@/components/shared/internalusers/OsInternalUsersByCustomerOverview.vue';
import { IAppuser } from '@/models/application';
@Component({
  components: {
    OsInternalUsersByCustomerOverview,
    OsAppusersOverview
  }
})
export default class CustomersInternalUsersIndex extends Vue {
  customerId!: string;

  async created() {
    this.customerId = this.$route.params.id;
  }
  openExisting(selected: IAppuser) {
    this.$router.push(`/settings/internalusers/${selected.id}`);
  }
}
