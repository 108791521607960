import { messages } from '@/localization';
export class LocalizationUtil {
  static localize(key: string): any {
    let result = key.split('.').reduce((o: any, i: string) => o[i], messages);

    if (result === undefined) {
      result = key;
    }

    return result;
  }
}
