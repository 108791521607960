
import { Component, Vue } from 'vue-property-decorator';
import OsButton from '@/components/core/OsButton.vue';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsCustomerForm from '@/components/shared/customers/OsCustomerForm.vue';
import { ICustomer } from '@/models/application';
import { customersApi } from '@/apis';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
@Component({
  components: { OsCustomerForm, OsEntityToolbar, OsButton }
})
export default class CustomersIdProfile extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  customer: ICustomer | null = null;

  loading = false;
  async mounted() {
    this.customer = await customersApi.getCustomerById(this.$route.params.id);
    this.initAppDirtyUtil();
  }

  async onSave() {
    this.loading = true;
    try {
      this.customer!.id = this.$route.params.id;
      this.customer = await customersApi.updateCustomer(this.customer!);
      this.initAppDirtyUtil();
      await this.$store.dispatch('app/updateCustomer', this.customer);
    } finally {
      this.loading = false;
    }
  }

  undoClicked() {
    const logoUrl = this.customer!.logourl; // in case logourl has been updated, then it should not be undid because its already saved on db
    this.customer = this.appDirtyUtil.getOriginalObject();
    this.initAppDirtyUtil();
    this.customer!.logourl = logoUrl;
  }

  initAppDirtyUtil() {
    this.appDirtyUtil.init(this.customer, ['logourl']);
  }
}
