
import { ICustomer } from '@/models/application';
import { Component, PropSync } from 'vue-property-decorator';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import Vue from 'vue';
import moment from 'moment';
import { customersApi, registrationApi } from '@/apis';

@Component({
  components: { OsAlertBox }
})
export default class OsAdminTrialBanner extends Vue {
  @PropSync('customer')
  internalCustomer!: ICustomer;

  selectedDate: string | null = null;
  minDate: string | null = null;

  showDatePicker = false;

  mounted() {
    this.selectedDate = moment(this.internalCustomer.trialEnddate).format(
      'YYYY-MM-DD'
    );
    this.minDate = moment().add(1, 'day').format('YYYY-MM-DD');
  }

  async convertToFullCustomer() {
    const confirmation = await this.$global.dialogs.confirm(
      'Confirm action',
      `Are you sure you want to convert this trial customer to a full customer? All appusers will be converted to normal users. No Admin will be set.`
    );

    if (!confirmation) {
      return;
    }

    const result = await registrationApi.convertTrialAccountToFullCustomer(
      this.internalCustomer.id
    );

    await this.$global.dialogs.info(
      'Success',
      `All users has been converted to normal customer portal users. No admin user has been set. You can promote a user of your choice in the app users section.`
    );

    if (result) {
      location.reload();
    }
  }

  async changeEnddate(date: string) {
    const mDate = moment(date).endOf('day');

    const updatedCustomer = await customersApi.updateCustomer({
      ...this.internalCustomer,
      trialEnddate: mDate.toISOString()
    });

    if (updatedCustomer) {
      this.internalCustomer.trialEnddate = updatedCustomer.trialEnddate;

      this.showDatePicker = false;
    }
  }
}
