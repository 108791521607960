import { BaseApi } from '@/apis/BaseApi';
import { IGlobalSearchParticipant, IParticipant } from '@/models/application';

export class ParticipantsApi extends BaseApi {
  constructor() {
    super('participants');
  }

  async updateParticipant(participant: IParticipant): Promise<IParticipant> {
    return (await this.put<IParticipant>(``, participant)).result;
  }

  async getParticipants(): Promise<IGlobalSearchParticipant[]> {
    return (await this.get<IGlobalSearchParticipant[]>(``)).result;
  }

  async getByCustomerId(
    customerId: string
  ): Promise<IGlobalSearchParticipant[]> {
    return (await this.get<IGlobalSearchParticipant[]>(`/${customerId}`))
      .result;
  }
}
