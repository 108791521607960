
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { IMenuItem } from '@/models/navigation';
import { StringValidationUtil } from '@/utils/StringValidationUtil';

@Component
export default class OsAppSubMenu extends Vue {
  @PropSync('menuItems', { required: true })
  menuItemsInternal!: IMenuItem[];

  @Prop({ default: false })
  tabLayout!: boolean;

  @Prop({ default: '' })
  menuHeader!: string;

  get showMenuHeader() {
    return !StringValidationUtil.isNullOrWhitespace(this.menuHeader);
  }
}
