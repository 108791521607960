import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Login } from '@/pages';

import customers from '@/router/customers';
import setPassword from '@/router/set-password';
import globalParticipants from '@/router/global-participants';
import settings from '@/router/settings';
import myprofile from '@/router/myprofile';
import store from '@/store';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import { IGlobalFunctions } from '@/types/IGlobalFunctions';
import statistics from '@/router/statistics';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'Root',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      anonymousAllowed: true
    }
  },
  setPassword,
  statistics,
  customers,
  globalParticipants,
  settings,
  myprofile
  // { path: '*', redirect: '/dashboard' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  if (!isAuthenticated && !to.meta?.anonymousAllowed) {
    return next('/login');
  }

  if (AppDirtyUtil.isDirty) {
    const discardChanges = await (
      Vue.prototype.$global as IGlobalFunctions
    ).dialogs.confirm(
      'Unsaved changes',
      'This Page has unsaved changes. Are you sure you want to discard changes and leave this page?'
    );

    if (discardChanges) {
      AppDirtyUtil.setDirty(false);
    } else {
      return;
    }
  }

  return next();
});

export default router;
