
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IExtendedDataTableHeader } from '@/models/core';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSearchField from '@/components/core/OsSearchField.vue';
@Component({
  components: { OsSearchField, OsSheet, OsToolbar }
})
export default class OsDatatable extends Vue {
  @Prop({ required: false, default: () => [] })
  extraButtons!: any[];

  @Prop({ required: false, default: '' })
  title!: string;

  @Prop({ required: true, default: [] })
  value!: any[];

  @Prop({ required: true, default: [] })
  headers!: IExtendedDataTableHeader[];

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  flat!: boolean;

  @Prop({ default: undefined })
  sortBy!: '';

  @Prop({ default: undefined })
  sortDesc!: boolean;

  @Prop({ default: 'grey lighten-3' })
  headerBackgroundColor!: string;

  @Prop({ default: true })
  createEnabled!: boolean;

  @Prop({ default: 'mdi-plus' })
  createIcon!: string;

  @Prop({ default: true })
  createVisible!: boolean;

  @Prop({ default: 'New' })
  createLabel!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: true })
  selectableRecords!: boolean;

  @Prop({ default: () => [10, 25, 50, -1] })
  itemsPerPageOptions!: number[];

  @Prop()
  searchBarLabel!: string;

  @Prop()
  loadingDataLabel!: string;

  @Prop({ default: true })
  useMinHeight!: boolean;

  @Prop({ default: false })
  showSearchBar!: boolean;

  @Prop({ default: undefined })
  itemClass!: (item: any) => string;

  @Prop({ default: 10 })
  itemsPerPage!: number;

  search = '';
  currentVisibleItems: any[] = [];

  get loadingDataLabelInternal(): string {
    return 'loading data...';
  }

  get calculatedMinHeight() {
    if (!this.useMinHeight) {
      return undefined;
    }

    const minHeightInPx = '530px';

    if (this.value && this.value.length < 10) {
      return minHeightInPx;
    }

    if (
      this.itemsPerPage === 10 &&
      this.currentVisibleItems &&
      this.currentVisibleItems.length < 10
    ) {
      return minHeightInPx;
    }

    return undefined;
  }

  getValue(h: IExtendedDataTableHeader, v: any, item: any) {
    if (
      h &&
      h.formatter &&
      Object.prototype.hasOwnProperty.call(h, 'formatter')
    ) {
      return h.formatter(v, item);
    }

    return v;
  }

  getIsVisible(h: IExtendedDataTableHeader, v: any, item: any) {
    if (h && h.visible && Object.prototype.hasOwnProperty.call(h, 'visible')) {
      return h.visible(item);
    }

    return true;
  }

  getIcon(h: IExtendedDataTableHeader, v: any) {
    if (h && h.icon && typeof h.icon === 'function') {
      return h.icon(v);
    }

    return h.icon;
  }
}
