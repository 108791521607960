import { render, staticRenderFns } from "./OsParticipantOverview.vue?vue&type=template&id=245660ae&scoped=true"
import script from "./OsParticipantOverview.vue?vue&type=script&lang=ts"
export * from "./OsParticipantOverview.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245660ae",
  null
  
)

export default component.exports