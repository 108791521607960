var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-row align-start p-relative"},[_c('os-antes-disc',{attrs:{"d":_vm.internalD,"i":_vm.internalI,"s":_vm.internalS,"c":_vm.internalC,"width":_vm.width,"height":_vm.height,"fixed-max-y":24,"is-disc-ws":_vm.isDiscWs},on:{"update:d":function($event){_vm.internalD=$event},"update:i":function($event){_vm.internalI=$event},"update:s":function($event){_vm.internalS=$event},"update:c":function($event){_vm.internalC=$event}}}),_c('div',{staticClass:"os-slider-container",class:[_vm.disabled ? 'os-disabled' : '']},[_c('div',{staticClass:"d-flex flex-row",style:({ width: _vm.width + 'px' })},[(!_vm.disabled)?_c('v-slider',{style:({
          'padding-left': _vm.width / 5 + 'px'
        }),attrs:{"thumb-color":"transparent","track-fill-color":"transparent","track-color":"transparent","vertical":"","min":-20,"max":20,"step":1},on:{"change":_vm.onChange,"mousedown":_vm.onDragStart,"mouseup":_vm.onDragEnd},model:{value:(_vm.internalD),callback:function ($$v) {_vm.internalD=$$v},expression:"internalD"}}):_vm._e(),(!_vm.disabled)?_c('v-slider',{style:({
          'padding-left': _vm.width / 5 + 'px'
        }),attrs:{"thumb-color":"transparent","track-fill-color":"transparent","track-color":"transparent","vertical":"","min":-20,"max":20,"step":1},on:{"change":_vm.onChange,"mousedown":_vm.onDragStart,"mouseup":_vm.onDragEnd},model:{value:(_vm.internalI),callback:function ($$v) {_vm.internalI=$$v},expression:"internalI"}}):_vm._e(),(!_vm.disabled)?_c('v-slider',{style:({
          'padding-left': _vm.width / 5 + 'px'
        }),attrs:{"thumb-color":"transparent","track-fill-color":"transparent","track-color":"transparent","vertical":"","min":-20,"max":20,"step":1},on:{"change":_vm.onChange,"mousedown":_vm.onDragStart,"mouseup":_vm.onDragEnd},model:{value:(_vm.internalS),callback:function ($$v) {_vm.internalS=$$v},expression:"internalS"}}):_vm._e(),(!_vm.disabled)?_c('v-slider',{style:({
          'padding-left': _vm.width / 5 + 'px',
          'padding-right': _vm.width / 5 + 'px'
        }),attrs:{"thumb-color":"transparent","track-fill-color":"transparent","track-color":"transparent","vertical":"","min":-20,"max":20,"step":1},on:{"change":_vm.onChange,"mousedown":_vm.onDragStart,"mouseup":_vm.onDragEnd},model:{value:(_vm.internalC),callback:function ($$v) {_vm.internalC=$$v},expression:"internalC"}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }