
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';

export enum CreateTargetProfileType {
  blank = 'blank',
  guided = 'guided',
  link = 'link'
}

@Component({
  components: { OsButton, OsInputTextField, OsSheet, OsEntityForm }
})
export default class OsCreateTargetProfileForm extends Vue {
  @Prop({ required: true })
  customerId!: string;

  @Prop({ required: true })
  campaignId!: string;

  @Prop({ default: 'Target Profile name' })
  textTargetProfileName!: string;

  @Prop({ default: 'Blank Target Profile' })
  textEmptyTargetProfile!: string;

  @Prop({ default: 'Create Target Profile' })
  textCreateTargetProfile!: string;

  @Prop({ default: 'Take guided Tour' })
  textOpenGuidedQuestions!: string;

  @Prop({
    default:
      '<span class="font-weight-bold">blank profile</span>, configurable by <br /><span class="font-weight-bold">drag and drop</span>'
  })
  textEmptyTargetProfileSubText!: string;

  @Prop({ default: 'Guided creation' })
  guidedButtonTitle!: string;

  @Prop({
    default:
      '<span class="font-weight-bold">target profile</span>,<br />created by <span class="font-weight-bold">questions</span>'
  })
  guidedButtonSubText!: string;

  getButtonText(n: number): string {
    if (n === 1) {
      return this.textEmptyTargetProfile;
    } else if (n === 2) {
      return this.guidedButtonTitle;
    }

    return 'External Link';
  }

  getButtonIcon(n: number): string {
    if (n === 1) {
      return 'mdi-book-open-blank-variant';
    } else if (n === 2) {
      return 'mdi-map-marker-path';
    }

    return 'mdi-link-variant';
  }

  getButtonSubtitle(n: number): string {
    if (n === 1) {
      return this.textEmptyTargetProfileSubText;
    } else if (n === 2) {
      return this.guidedButtonSubText;
    }

    return `<span class="font-weight-bold">external link</span>,<br />with guided <span class="font-weight-bold">questions</span>`;
  }

  get submitButtonText(): string {
    if (this.selectedType === CreateTargetProfileType.blank) {
      return this.textCreateTargetProfile;
    }

    if (this.selectedType === CreateTargetProfileType.guided) {
      return this.textOpenGuidedQuestions;
    }

    if (this.selectedType === CreateTargetProfileType.link) {
      return 'Create Link';
    }

    return '';
  }

  selectedIndexType: number | undefined = -1;
  loading = false;

  targetProfileName = '';

  get selectedType(): CreateTargetProfileType | null {
    if (this.selectedIndexType === 0) {
      return CreateTargetProfileType.blank;
    }

    if (this.selectedIndexType === 1) {
      return CreateTargetProfileType.guided;
    }

    if (this.selectedIndexType === 2) {
      return CreateTargetProfileType.link;
    }

    return null;
  }

  get isValidForm() {
    return (
      (this.selectedIndexType === 0 ||
        this.selectedIndexType === 1 ||
        this.selectedIndexType === 2) &&
      !!this.targetProfileName
    );
  }

  reset() {
    this.selectedIndexType = -1;
  }

  async onSubmit() {
    if (this.isValidForm) {
      this.loading = true;
      try {
        this.$emit('click:create', {
          name: this.targetProfileName,
          type: this.selectedType
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
