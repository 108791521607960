import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { IAppuser } from '@/models/application';
import { appuserApi } from '@/apis';

class State {
  appuser: IAppuser | null = null;
}

const mutations = <MutationTree<State>>{
  setAppUser(state, payload: IAppuser) {
    state.appuser = payload;
  }
};

const actions = <ActionTree<State, any>>{
  async fetchLoggedInUserData(
    store,
    payload: { appuserId: string }
  ): Promise<void> {
    if (!payload) {
      return;
    }

    const appuser = await appuserApi.getById(payload.appuserId);
    this.commit('user/setAppUser', appuser);
  }
};

const getters = <GetterTree<State, any>>{};

const UserModule = {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default UserModule;
