
import { Component, ModelSync, Prop, Vue, Watch } from 'vue-property-decorator';
import { StringValidationUtil } from '@/utils/StringValidationUtil';

@Component
export default class OsInputTextField extends Vue {
  $refs!: {
    textField: Vue;
  };

  @ModelSync('value', 'changed', { required: true, default: '' })
  internalValue!: string | number | null;

  @Prop({ required: false, default: false })
  readonly!: boolean;

  @Prop({ required: true, default: '' })
  label!: string;

  @Prop({ required: false, default: '' })
  type!: string;

  @Prop({ required: false, default: false })
  required!: boolean;

  @Prop({ required: false, default: undefined })
  prependIcon!: string;

  @Prop({ required: false, default: undefined })
  appendIcon!: string;

  @Prop({ required: false, default: undefined })
  prependInnerIcon!: string;

  @Prop({ required: false, default: undefined })
  appendOuterIcon!: string;

  @Prop({ required: false, default: undefined })
  min!: string | number;

  @Prop({ required: false, default: undefined })
  max!: string | number;

  @Prop({ required: false, default: false })
  handleEmptyAsNull!: boolean;

  get rules(): any {
    const rules = [];

    if (this.required) {
      rules.push((v: string) => !!v || 'required field');
    }

    if (this.type === 'email') {
      rules.push((v: string) => v.length > 6 || 'required field');
      rules.push((v: string) => {
        return StringValidationUtil.isEmailValid(v) || 'invalid email';
      });
    }

    return rules;
  }

  @Watch('internalValue')
  onValueChanged() {
    if (this.internalValue === null) {
      return;
    }

    if (this.min) {
      if (this.internalValue < this.min) {
        this.internalValue = this.min as string;
      }
    }

    if (this.max) {
      if (this.internalValue > this.max) {
        this.internalValue = this.max as string;
      }
    }

    if (this.handleEmptyAsNull) {
      if (this.internalValue === '') {
        this.internalValue = null;
      }
    }
  }
}
