
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsButton from '@/components/core/OsButton.vue';
import { IParticipant } from '@/models/application';
import { customersApi } from '@/apis';
import OsLanguageSelection from '@/components/shared/OsLanguageSelection.vue';
@Component({
  components: { OsLanguageSelection, OsButton }
})
export default class OsParticipantPdfDownloadBtn extends Vue {
  @Prop({ default: 'en' })
  lang!: string;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ required: true, default: false })
  participant!: IParticipant;

  @Prop({ required: true, default: false })
  customerId!: string;

  @Prop({ default: 'Download DISC report ...' })
  textDownloadReportButtonTitle!: string;

  @Prop({ default: 'Participant' })
  textDownloadReportButtonParticipantTitle!: string;

  @Prop({ default: 'DISC Report for the Participant' })
  textDownloadReportButtonParticipantSubTitle!: string;

  @Prop({ default: 'Internal Report' })
  textDownloadReportButtonParticipantInternalTitle!: string;

  @Prop({ default: 'DISC Report for internal use' })
  textDownloadReportButtonParticipantInternalSubTitle!: string;

  loading = false;

  async renderPdf(lang: string, textInThirdPerson: boolean) {
    this.loading = true;
    let filename = `ANTES Profile ${this.participant.firstname} ${this.participant.lastname}.pdf`;
    filename = filename.replaceAll(' ', '_');
    filename = encodeURIComponent(filename);

    await customersApi.downloadParticipantTargetProfileReportAsPdf(
      filename,
      this.customerId,
      this.participant.id,
      lang,
      textInThirdPerson
    );

    this.loading = false;
  }
}
