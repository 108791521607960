
import { Component, Vue } from 'vue-property-decorator';
import {
  ICustomerQuestionnaireSettings,
  ICustomerSetting
} from '@/models/application';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsCustomerSettingsForm from '@/components/shared/customer-settings/OsCustomerSettingsForm.vue';
import { customersApi } from '@/apis';
@Component({
  components: { OsCustomerSettingsForm }
})
export default class CustomersSettings extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();
  customerId!: string;
  loading = false;
  shortQuestionnaire = '0fc2c7c7-1837-4092-9d41-c463ea22e63f';
  longQuestionnaire = '37f4ad4d-f2cf-4e2d-85cd-eacd966b84f9';

  questionnaireSettings: ICustomerQuestionnaireSettings = {
    useShortQuestionnaire: false,
    useLongQuestionnaire: false
  };

  settings: ICustomerSetting | null = null;

  async created() {
    this.customerId = this.$route.params.id;

    this.settings = await customersApi.getSettings(this.customerId);

    const result = await customersApi.getQuestionnaires(this.customerId);
    if (result.findIndex((r) => r.id === this.shortQuestionnaire) >= 0) {
      this.questionnaireSettings.useShortQuestionnaire = true;
    }
    if (result.findIndex((r) => r.id === this.longQuestionnaire) >= 0) {
      this.questionnaireSettings.useLongQuestionnaire = true;
    }
    this.appDirtyUtil.init(
      {
        ...this.questionnaireSettings,
        ...this.settings
      },
      ['id', 'customerId']
    );
  }

  async onSave() {
    this.loading = true;
    try {
      await this.updateQuestionnaires();
      this.settings = await customersApi.updateSettings(
        this.customerId,
        this.settings!
      );
      this.appDirtyUtil.init(
        {
          ...this.questionnaireSettings,
          ...this.settings
        },
        ['id', 'customerId']
      );
    } finally {
      this.loading = false;
    }
  }

  undoClicked() {
    this.questionnaireSettings = this.appDirtyUtil.getOriginalObject();
    this.settings = this.appDirtyUtil.getOriginalObject();
    this.appDirtyUtil.init(
      {
        ...this.questionnaireSettings,
        ...this.settings
      },
      ['id', 'customerId']
    );
  }

  async updateQuestionnaires() {
    const initialCustomerSettings = this.appDirtyUtil.getOriginalObject();
    if (this.questionnaireSettings.useShortQuestionnaire) {
      if (!initialCustomerSettings.useShortQuestionnaire) {
        await customersApi.linkQuestionnaireToCustomerAsync(
          this.customerId,
          this.shortQuestionnaire
        );
      }
    } else {
      if (initialCustomerSettings.useShortQuestionnaire) {
        await customersApi.unlinkQuestionnaireFromCustomer(
          this.customerId,
          this.shortQuestionnaire
        );
      }
    }
    if (this.questionnaireSettings.useLongQuestionnaire) {
      if (!initialCustomerSettings.useLongQuestionnaire) {
        await customersApi.linkQuestionnaireToCustomerAsync(
          this.customerId,
          this.longQuestionnaire
        );
      }
    } else {
      if (initialCustomerSettings.useLongQuestionnaire) {
        await customersApi.unlinkQuestionnaireFromCustomer(
          this.customerId,
          this.longQuestionnaire
        );
      }
    }
  }
}
