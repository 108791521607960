import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#4585f5',
        secondary: '#405092',
        accent: '#f26430',
        error: '#d30028',
        info: '#4585f5',
        success: '#4CAF50',
        warning: '#f26430'
      }
    }
  }
});
