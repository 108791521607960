
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import _ from 'lodash';
import OsButton from '@/components/core/OsButton.vue';
import OsCloseButton from '@/components/core/OsCloseButton.vue';
import { ICustomer } from '@/models/application';
import { customersApi } from '@/apis';
import { StringValidationUtil } from '@/utils/StringValidationUtil';

@Component({
  components: { OsCloseButton, OsButton, OsSheetContent, OsSheet }
})
export default class OsCustomerOverview extends Vue {
  @PropSync('searchValue', { required: false })
  internalSearchValue!: string;

  @Prop({ required: false, default: false })
  trialOnly!: boolean;

  maxGroupItems = 5;
  viewOnlyGroup: string | null = null;
  customers: ICustomer[] = [];
  get groupedCustomers(): any {
    return _(this.customers)
      .filter((c: ICustomer) => {
        if (this.trialOnly) {
          return !!c.trialEnddate;
        }

        return true;
      })
      .filter(
        (customer: ICustomer) =>
          StringValidationUtil.isNullOrWhitespace(this.internalSearchValue) ||
          (!StringValidationUtil.isNullOrWhitespace(customer?.name) &&
            customer.name
              .toLowerCase()
              .indexOf(this.internalSearchValue.toLowerCase()) >= 0)
      )
      .orderBy((customer: any) => {
        return customer.name.toLowerCase();
      })
      .groupBy((customer: any) => {
        return customer.name.toLowerCase().charAt(0);
      })
      .value();
  }

  async mounted() {
    this.customers = await customersApi.getAllCustomers();
  }
}
