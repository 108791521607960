
import { Component, Vue } from 'vue-property-decorator';
import {
  CustomerState,
  Gender,
  ICustomer,
  ICustomerSetting,
  IParticipant
} from '@/models/application';
import { GuidUtil } from '@/utils/GuidUtil';
import OsCustomerForm from '@/components/shared/customers/OsCustomerForm.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import OsInputSelect from '@/components/core/input/OsInputSelect.vue';
import OsAntesDisc from '@/components/chart/OsAntesDisc.vue';
import OsAntesDiscEditor from '@/components/chart/OsAntesDiscEditor.vue';
import OsButton from '@/components/core/OsButton.vue';
import { reportsApi } from '@/apis';
import OsAntesDiscEditorValidator from '@/components/chart/OsAntesDiscEditorValidator.vue';
import { Alignment } from '@/models/application/Alignment';
import OsInputCheckbox from '@/components/core/input/OsInputCheckbox.vue';
import { supportedLanguages } from '@/lang';

@Component({
  methods: {
    supportedLanguages() {
      return supportedLanguages;
    }
  },
  components: {
    OsInputCheckbox,
    OsAntesDiscEditorValidator,
    OsButton,
    OsAntesDiscEditor,
    OsAntesDisc,
    OsInputSelect,
    OsEntityForm,
    OsCustomerForm
  }
})
export default class OsTestReportForm extends Vue {
  discIsValid = false;
  discWsIsValid = false;
  showDiscWs = false;

  get hasWsValues(): boolean {
    return (
      this.participant.dValueWs! != 0 ||
      this.participant.iValueWs! != 0 ||
      this.participant.sValueWs! != 0 ||
      this.participant.cValueWs! != 0
    );
  }

  get formIsValid(): boolean {
    return (
      this.customerIsValid &&
      this.participantIsValid &&
      this.discIsValid &&
      (!this.showDiscWs || this.discWsIsValid)
    );
  }

  loading = false;

  languages = supportedLanguages.map((l) => {
    return { value: l.code, key: l.name };
  });

  customer: ICustomer = {
    id: GuidUtil.empty(),
    state: CustomerState.Active,
    logourl: '',
    name: 'Test Customer GmbH',
    street: 'Flower Street 12',
    city: 'Main City',
    website: 'https://www.antes-group.com/',
    telephone: '+49 1234 5678',
    emailaddress: 'office@antes-group.com',
    branch: '',
    primarycontactname: '',
    primarycontactemailaddress: '',
    trialEnddate: null
  };

  get customerFields(): IEntityFormField[] {
    let fields = [
      {
        type: EntityFormFieldType.text,
        label: 'Companyname',
        propName: 'name',
        smCols: 4,
        required: true
      }
    ];

    if (this.customerSetting.useAddressInReports) {
      fields.push(
        {
          type: EntityFormFieldType.text,
          label: 'Street',
          propName: 'street',
          smCols: 4,
          required: true
        },
        {
          type: EntityFormFieldType.text,
          label: 'City',
          propName: 'city',
          smCols: 4,
          required: true
        },
        {
          type: EntityFormFieldType.text,
          label: 'Telephone',
          propName: 'telephone',
          smCols: 4,
          required: true
        },
        {
          type: EntityFormFieldType.text,
          label: 'E-Mail',
          propName: 'emailaddress',
          smCols: 4,
          required: true
        },
        {
          type: EntityFormFieldType.text,
          label: 'Website',
          propName: 'website',
          smCols: 12,
          required: true
        }
      );
    }

    if (this.customerSetting.useBrandedReports) {
      fields.push({
        type: EntityFormFieldType.text,
        label: 'Logo URL',
        propName: 'logourl',
        smCols: 6,
        required: true
      });
    }

    return fields;
  }

  customerIsValid = false;

  customerSetting: ICustomerSetting = {
    customerId: GuidUtil.empty(),
    id: GuidUtil.empty(),
    languagecode: '',
    maxAppuserAmount: 0,
    maxIndividualLinkAmount: 0,
    maxGlobalLinkAmount: 0,
    logoAlignment: Alignment.left,
    useAddressInReports: false,
    useBrandedReports: false,
    useBrandedTemplates: false,
    logoEnabled: true,
    anonymizeParticipantsAfterDays: null
  };

  participant: IParticipant = {
    id: GuidUtil.empty(),
    campaignId: GuidUtil.empty(),
    gender: Gender.Male,
    emailaddress: '',
    firstname: 'Max',
    lastname: 'Mustermann',
    languagecode: 'en',
    companyname: '',
    dValue: 0,
    iValue: 0,
    sValue: 0,
    cValue: 0,
    dValueWs: 0,
    iValueWs: 0,
    sValueWs: 0,
    cValueWs: 0
  };

  participantFields: IEntityFormField[] = [
    {
      type: EntityFormFieldType.radio,
      required: true,
      label: '',
      propName: 'gender',
      mdCols: 12,
      radioAsRow: true,
      items: [
        {
          key: 'male',
          value: Gender.Male
        },
        {
          key: 'female',
          value: Gender.Female
        }
      ]
    },
    {
      type: EntityFormFieldType.text,
      label: 'Participant firstname',
      propName: 'firstname',
      smCols: 6,
      required: true
    },
    {
      type: EntityFormFieldType.text,
      label: 'Participant lastname',
      propName: 'lastname',
      smCols: 6,
      required: true
    }
  ];
  participantIsValid = false;

  languagecode = 'en';
  textInThirdPerson = false;

  async generateReport() {
    this.loading = true;

    if (!this.showDiscWs) {
      this.participant.dValueWs = 0;
      this.participant.iValueWs = 0;
      this.participant.sValueWs = 0;
      this.participant.cValueWs = 0;
    }

    await reportsApi.renderParticipantActualProfile(
      'Testreport.pdf',
      this.customer,
      this.customerSetting,
      this.participant,
      this.languagecode,
      this.textInThirdPerson
    );

    this.loading = false;
  }
}
