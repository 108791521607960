var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('os-sheet',{class:[_vm.disabled ? 'disabled' : '']},[_c('os-toolbar',{attrs:{"title":_vm.title}},[(this.$slots.filter)?_c('v-menu',{attrs:{"bottom":"","left":"","nudge-bottom":"28"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,false,743401260)},[_vm._t("filter")],2):_vm._e(),(_vm.showSearchBar)?_c('os-search-field',{attrs:{"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}}):_vm._e(),(_vm.createVisible)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","disabled":!_vm.createEnabled},on:{"click":function($event){return _vm.$emit('create')}}},[_vm._v(_vm._s(_vm.createLabel)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.createIcon))])],1):_vm._e(),_vm._l((_vm.extraButtons),function(b,i){return _c('v-btn',{key:i,staticClass:"ml-2",attrs:{"color":"primary","disabled":!b.enabled},on:{"click":function($event){return b.action()}}},[_vm._v(_vm._s(b.title)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(b.icon))])],1)})],2),_vm._t("header"),_c('v-data-table',{class:[
      'data-table transparent elevation-0',
      _vm.selectableRecords ? 'os-selectable-rows' : ''
    ],attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"item-class":_vm.itemClass,"headers":_vm.headers,"items":_vm.value,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.loadingDataLabelInternal,"footer-props":{
      'items-per-page-options': _vm.itemsPerPageOptions
    },"height":_vm.calculatedMinHeight},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":function($event){return _vm.$emit('rowclicked', $event)},"current-items":(e) => (_vm.currentVisibleItems = e)},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:`item.${header.value}`,fn:function({ header, value, item }){return [_c('div',{key:header.value},[(header.hasOwnProperty('icon'))?_c('div',[(_vm.getIsVisible(header, value, item))?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();header.hasOwnProperty('action') ? header.action(item) : null}}},[_vm._v(_vm._s(_vm.getIcon(header, value)))]):_vm._e()],1):_c('div',[(
              header.hasOwnProperty('action') &&
              _vm.getIsVisible(header, value, item)
            )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getValue(header, value, item))},on:{"click":function($event){$event.stopPropagation();return header.action(item)}}}):(_vm.getIsVisible(header, value, item))?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getValue(header, value, item))}}):_vm._e()])])]}}}),_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(props){return [_vm._t(slot,null,null,props)]}}})],null,true)}),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }