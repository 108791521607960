
import { Component, Vue } from 'vue-property-decorator';
import OsAppUsersByCampaignOverview from '@/components/shared/appusers/OsAppUsersByCampaignOverview.vue';
import OsAppusersOverview from '@/components/shared/appusers/OsAppusersOverview.vue';
@Component({
  components: {
    OsAppusersOverview,
    OsAppUsersByCampaignOverview
  }
})
export default class CampaignAppUsersIndex extends Vue {
  customerId!: string;
  campaignId!: string;

  async created() {
    this.customerId = this.$route.params.id;
    this.campaignId = this.$route.params.campaignid;
  }
}
