
import { Component, Vue } from 'vue-property-decorator';
import OsCampaignOverview from '@/components/shared/campaigns/OsCampaignOverview.vue';
@Component({
  components: {
    OsCampaignOverview
  }
})
export default class CampaignIndex extends Vue {
  customerId!: string;

  created() {
    this.customerId = this.$route.params.id;
  }

  rowClicked(selected: any) {
    this.$router.push(`/customers/${this.customerId}/campaigns/` + selected.id);
  }

  create() {
    this.$router.push(`/customers/${this.customerId}/campaigns/create`);
  }
}
