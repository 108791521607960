import { render, staticRenderFns } from "./OsRequestPasswordResetForm.vue?vue&type=template&id=018c5945&scoped=true"
import script from "./OsRequestPasswordResetForm.vue?vue&type=script&lang=ts"
export * from "./OsRequestPasswordResetForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018c5945",
  null
  
)

export default component.exports