
import { Component, Vue } from 'vue-property-decorator';
import { questionnairesApi } from '@/apis';
import { IQuestionnaire } from '@/models/application';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
@Component({
  components: { OsDatatable }
})
export default class OsQuestionnairesOverview extends Vue {
  header: IExtendedDataTableHeader[] = [
    {
      text: 'Title',
      value: 'title'
    }
    // {
    //   text: 'Description',
    //   value: 'description'
    // }
  ];
  questionnaires: IQuestionnaire[] = [];

  async mounted() {
    this.questionnaires = await questionnairesApi.getAll();
  }
}
