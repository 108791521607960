
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { AppuserState, IExtendedAppuser } from '@/models/application';
import { GuidUtil } from '@/utils/GuidUtil';
import OsAppuserForm from '@/components/shared/appusers/OsAppuserForm.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsInternalUserForm from '@/components/shared/internalusers/OsInternalUserForm.vue';
import { appuserApi } from '@/apis';
import OsAlertBox from '@/components/core/OsAlertBox.vue';

@Component({
  components: {
    OsAlertBox,
    OsInternalUserForm,
    OsAppuserForm,
    OsSheetContent,
    OsSheet
  }
})
export default class SettingsInternalUserCreate extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  appuser: IExtendedAppuser = {
    id: GuidUtil.empty(),
    customerId: null,
    emailaddress: '',
    firstname: '',
    lastname: '',
    state: AppuserState.Active,
    languagecode: 'en',
    isAdmin: false
  };

  loading = false;

  async onSave() {
    this.loading = true;
    try {
      const createdAppUser = await appuserApi.createAppuser(this.appuser);

      if (this.appuser?.isAdmin) {
        await appuserApi.setIsAdmin(createdAppUser.id, true);
      }
      this.appDirtyUtil.init(this.appuser);
      await this.$router.push(`/settings/internalusers/`);
    } finally {
      this.loading = false;
    }
  }
}
