import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// plugins
import vuetify from './plugins/vuetify';
import './plugins/axios';
import './plugins/vuex-persistedstate';
import './plugins/global-functions';
import './plugins/vue-clipboard2';
import './plugins/flag-icons';
import './plugins/apexcharts';

// extensions - pipes
import '@/plugins/vuejs-extensions/pipes/format-date.pipe';
import '@/plugins/vuejs-extensions/pipes/fixed-number.pipe';

// styles
import '@/assets/styles/index.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
