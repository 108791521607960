var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('os-datatable',{attrs:{"title":_vm.textHeader,"headers":_vm.header,"create-visible":false,"show-search-bar":true,"itemsPerPage":-1,"extra-buttons":[
      {
        title: 'Generate Test report',
        icon: 'mdi-file-document',
        enabled: true,
        action: () => {
          this.openTestDialog();
        }
      }
    ]},on:{"rowclicked":(e) => _vm.$emit('rowclicked', e)},model:{value:(_vm.reports),callback:function ($$v) {_vm.reports=$$v},expression:"reports"}}),_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.showTestDialog),callback:function ($$v) {_vm.showTestDialog=$$v},expression:"showTestDialog"}},[_c('os-sheet',[_c('os-sheet-content',[_c('os-test-report-form',{ref:"osTestReport",on:{"close":function($event){return _vm.closeTestDialog()}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }