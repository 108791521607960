
import { Component, Vue } from 'vue-property-decorator';
import OsParticipantOverview from '@/components/shared/participants/OsParticipantOverview.vue';
import { customersApi } from '@/apis';
import OsTargetProfileOverview from '@/components/shared/target-profiles/OsTargetProfileOverview.vue';
import { ITargetProfile } from '@/models/application/ITargetProfile';
@Component({
  components: { OsTargetProfileOverview, OsParticipantOverview }
})
export default class CampaignTargetProfilesIndex extends Vue {
  customerId!: string;
  campaignId!: string;
  targetProfiles: ITargetProfile[] = [];

  async created() {
    this.customerId = this.$route.params.id;
    this.campaignId = this.$route.params.campaignid;
    this.targetProfiles = await customersApi.getTargetProfiles(
      this.customerId,
      this.campaignId
    );
  }

  rowClicked(selected: any) {
    this.$router.push(
      `/customers/${this.customerId}/campaigns/${this.campaignId}/target-profiles/${selected.id}`
    );
  }
}
