
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OsAntesDisc extends Vue {
  dotColors = ['#ff0000', '#ffff00', '#00ff00', '#0000ff'];
  legend = ['D', 'I', 'S', 'C'];

  get chartMarginY(): number {
    if (this.chartOnly) {
      return 0;
    }

    return 30;
  }

  get chartMarginX(): number {
    if (this.chartOnly) {
      return 0;
    }
    return 5;
  }

  @Prop({ default: 22 })
  fixedMaxY!: number;

  @Prop({ default: false })
  isDiscWs!: boolean;

  @Prop({ default: 150 })
  width!: number;

  @Prop({ default: 250 })
  height!: number;

  @Prop({ default: 5 })
  circleSize!: number;

  @Prop({ default: 3.5 })
  strokeWidth!: number;

  @Prop({ default: false })
  chartOnly!: boolean;

  @Prop({ default: 0 })
  d!: number;

  @Prop({ default: 0 })
  i!: number;

  @Prop({ default: 0 })
  s!: number;

  @Prop({ default: 0 })
  c!: number;

  get maxY(): number {
    const maxValuePadding = 1;

    if (this.fixedMaxY == undefined) {
      return (
        (Math.max(
          ...[
            Math.abs(this.d),
            Math.abs(this.i),
            Math.abs(this.s),
            Math.abs(this.c)
          ]
        ) as number) + maxValuePadding
      );
    }

    return this.fixedMaxY;
  }

  getDotHeight(index: number): number {
    let dotValue = 0;
    switch (index) {
      case 1:
        dotValue = this.d;
        break;
      case 2:
        dotValue = this.i;
        break;
      case 3:
        dotValue = this.s;
        break;
      case 4:
        dotValue = this.c;
        break;
    }

    dotValue = dotValue * -1;

    return (
      this.height / 2 +
      (dotValue * (this.height / 2 - this.chartMarginY)) / this.maxY
    );
  }
}
