
import { Component, Prop, Vue } from 'vue-property-decorator';
import { customersApi } from '@/apis';
import { ICampaign, Recipientgroup } from '@/models/application';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
@Component({
  components: { OsDatatable }
})
export default class OsCampaignOverview extends Vue {
  @Prop({ required: true })
  customerId!: string;

  @Prop({ default: 'Campaigns' })
  textHeader!: string;
  @Prop({ default: 'Title' })
  textTitle!: string;
  @Prop({ default: 'Recipientgroup' })
  textRecipientgroup!: string;
  @Prop({ default: 'All' })
  textRecipientgroupAll!: string;
  @Prop({ default: 'Staff' })
  textRecipientgroupStaff!: string;
  @Prop({ default: 'Participants' })
  textRecipientgroupParticipants!: string;

  campaigns: ICampaign[] = [];

  headers: IExtendedDataTableHeader[] = [
    {
      text: this.textTitle,
      value: 'title'
    },
    {
      text: this.textRecipientgroup,
      value: 'recipientgroup',
      width: '210px',
      formatter: (v) => {
        switch (v) {
          case Recipientgroup.All:
            return this.textRecipientgroupAll;
          case Recipientgroup.Staff:
            return this.textRecipientgroupStaff;
          case Recipientgroup.Participants:
            return this.textRecipientgroupParticipants;
        }

        return '';
      }
    }
  ];

  async mounted() {
    this.campaigns = await customersApi.getCampaigns(this.customerId);
  }
}
