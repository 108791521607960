
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
@Component({
  components: { OsSheet }
})
export default class OsLanguageSelection extends Vue {
  @PropSync('lang', { required: false })
  internalLang!: string;

  @Prop({ default: 'icons' })
  type!: string;

  @Prop({ default: false })
  isPickerOnly!: boolean;

  selectedLocale = '';
  selectableLocales: {
    label: string;
    lang: string;
    icon: string;
  }[] = [
    {
      label: 'Englisch',
      lang: 'en',
      icon: 'fi-gb'
    },
    {
      label: 'Deutsch',
      lang: 'de',
      icon: 'fi-de'
    },
    {
      label: 'Dansk',
      lang: 'da',
      icon: 'fi-dk'
    }
  ];

  mounted() {
    if (this.isPickerOnly) {
      return;
    }

    this.selectedLocale = this.internalLang;
  }
}
