
import { Component, Vue } from 'vue-property-decorator';
import OsCustomFieldsOverview from '@/components/shared/custom-fields/OsCustomFieldsOverview.vue';
import OsCustomFieldForm from '@/components/shared/custom-fields/OsCustomFieldForm.vue';
import { ISurveyCustomField } from '@/models/application';
import { GuidUtil } from '@/utils/GuidUtil';
import { customersApi } from '@/apis';
import _ from 'lodash';
import { supportedLanguages } from '@/lang';
@Component({
  components: { OsCustomFieldForm, OsCustomFieldsOverview }
})
export default class CustomersCustomFieldsIndex extends Vue {
  $refs!: {
    overview: OsCustomFieldsOverview;
  };

  customerId!: string;

  showDialog = false;

  isCreateForm = false;

  selectedCustomField: ISurveyCustomField | null = null;

  loading = false;

  async created() {
    this.customerId = this.$route.params.id;
  }
  openExisting(selected: ISurveyCustomField) {
    this.isCreateForm = false;
    this.selectedCustomField = selected;
    this.showDialog = true;
  }
  openCreateForm() {
    this.isCreateForm = true;
    this.selectedCustomField = {
      id: GuidUtil.empty(),
      sequencenumber: -1,
      parentObjectId: this.customerId,
      labels: supportedLanguages.map((l) => {
        return {
          id: GuidUtil.empty(),
          surveyCustomFieldId: GuidUtil.empty(),
          languagecode: l.code,
          text: ''
        };
      })
    };

    this.showDialog = true;
  }

  async onSave(event: { value: any; origin: ISurveyCustomField }) {
    try {
      this.loading = true;
      if (this.isCreateForm) {
        const createdCustomField = await customersApi.createCustomField(
          this.customerId,
          {
            id: GuidUtil.empty(),
            parentObjectId: this.customerId,
            sequencenumber: event.value.sequencenumber,
            labels: supportedLanguages.map((l) => {
              return {
                id: GuidUtil.empty(),
                surveyCustomFieldId: GuidUtil.empty(),
                languagecode: l.code,
                text: event.value[l.code]
              };
            })
          }
        );

        if (!createdCustomField) {
          return;
        }
      } else {
        const customFieldToUpdate = _.clone(event.origin);

        customFieldToUpdate.sequencenumber = event.value.sequencenumber;

        supportedLanguages.forEach((l) => {
          customFieldToUpdate.labels.find(
            (cfl) => cfl.languagecode === l.code
          )!.text = event.value[l.code];
        });

        // customFieldToUpdate.labels.find(
        //   (cfl) => cfl.languagecode === 'en'
        // )!.text = event.value.en;
        // customFieldToUpdate.labels.find(
        //   (cfl) => cfl.languagecode === 'de'
        // )!.text = event.value.de;
        // customFieldToUpdate.labels.find(
        //   (cfl) => cfl.languagecode === 'da'
        // )!.text = event.value.da;

        await customersApi.updateCustomField(
          this.customerId,
          customFieldToUpdate
        );
      }

      this.showDialog = false;
      await this.$refs.overview.refresh();
    } finally {
      this.loading = false;
    }
  }
}
