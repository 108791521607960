import { BaseApi } from '@/apis/BaseApi';
import { ICustomer } from '@/models/application';
import { IRegisterRequest } from '@/models/authentication/IRegisterRequest';
import { IRegisterResponse } from '@/models/authentication/IRegisterResponse';

export class RegistrationApi extends BaseApi {
  constructor() {
    super('registration');
  }

  async registerTrialAccount(
    request: IRegisterRequest
  ): Promise<IRegisterResponse> {
    return (
      await this.post<IRegisterResponse>(
        '/registertrialaccount',
        request,
        false,
        false
      )
    ).result;
  }

  async convertTrialAccountToFullCustomer(
    customerId: string
  ): Promise<ICustomer> {
    return (
      await this.post<ICustomer>(
        '/converttrialaccounttofullcustomer',
        customerId,
        true,
        true,
        {
          'Content-Type': 'application/json'
        }
      )
    ).result;
  }
}
