import { Customers } from '@/pages';
import {
  CustomersAppUsers,
  CustomersAppUsersById,
  CustomersAppUsersCreate,
  CustomersAppUsersIndex,
  CustomersIdIndex,
  CustomersIdProfile,
  CustomersIndex,
  CustomersCreate
} from '@/pages/customers/index';
import Placeholder from '@/components/Placeholder.vue';
import { RouteConfig } from 'vue-router';
import Campaigns from '@/pages/customers/_id/Campaigns.vue';
import {
  CampaignById,
  CampaignByIdIndex,
  CampaignCreate,
  CampaignIndex,
  CampaignParticipantById,
  CampaignParticipantsIndex
} from '@/pages/customers/_id/campaigns/index';
import CustomersSettings from '@/pages/customers/_id/Settings.vue';
import CampaignTargetProfileIndex from '@/pages/customers/_id/campaigns/_id/target-profiles/Index.vue';
import CampaignTargetProfileById from '@/pages/customers/_id/campaigns/_id/target-profiles/_id.vue';
import Participants from '@/pages/customers/_id/Participants.vue';
import CustomersParticipantsIndex from '@/pages/customers/_id/participants/Index.vue';
import CustomersCustomFields from '@/pages/customers/_id/CustomFields.vue';
import CustomersCustomFieldsIndex from '@/pages/customers/_id/custom-fields/Index.vue';
import CustomersInternalUsersIndex from '@/pages/customers/_id/internalusers/Index.vue';
import CustomersInternalUsers from '@/pages/customers/_id/Internalusers.vue';
import CampaignAppUsersIndex from '@/pages/customers/_id/campaigns/_id/appusers/Index.vue';

const routeConfig: RouteConfig = {
  path: '/customers',
  component: Customers,
  children: [
    {
      path: '',
      component: CustomersIndex
    },
    { path: 'create', component: CustomersCreate },
    {
      path: ':id',
      component: CustomersIdIndex,
      children: [
        {
          path: 'profile',
          component: CustomersIdProfile
        },
        {
          path: 'customfields',
          component: Placeholder
        },
        {
          path: 'campaigns',
          component: Campaigns,
          children: [
            {
              path: '',
              component: CampaignIndex
            },
            {
              path: 'create',
              component: CampaignCreate
            },
            {
              path: ':campaignid',
              component: CampaignById,
              children: [
                {
                  path: '',
                  component: CampaignByIdIndex
                },
                {
                  path: 'participants',
                  component: CampaignParticipantsIndex
                },
                {
                  path: 'participants/:participantId',
                  component: CampaignParticipantById
                },
                {
                  path: 'target-profiles',
                  component: CampaignTargetProfileIndex
                },
                {
                  path: 'target-profiles/:targetprofileid',
                  component: CampaignTargetProfileById
                },
                {
                  path: 'appusers',
                  component: CampaignAppUsersIndex
                }
              ]
            }
          ]
        },
        {
          path: 'participants',
          component: Participants,
          children: [
            {
              path: '',
              component: CustomersParticipantsIndex
            }
          ]
        },
        {
          path: 'settings',
          component: CustomersSettings
        },
        {
          path: 'targetprofiles',
          component: Placeholder
        },
        {
          path: 'statistics',
          component: Placeholder
        },
        {
          path: 'appusers',
          component: CustomersAppUsers,
          children: [
            {
              path: '',
              component: CustomersAppUsersIndex
            },
            {
              path: 'create',
              component: CustomersAppUsersCreate
            },
            {
              path: ':appuserId',
              component: CustomersAppUsersById
            }
          ]
        },
        {
          path: 'internalusers',
          component: CustomersInternalUsers,
          children: [
            {
              path: '',
              component: CustomersInternalUsersIndex
            }
          ]
        },
        {
          path: 'custom-fields',
          component: CustomersCustomFields,
          children: [
            {
              path: '',
              component: CustomersCustomFieldsIndex
            }
          ]
        },
        {
          path: '',
          redirect: 'profile'
        }
      ]
    }
  ]
};
export default routeConfig;
