
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
import OsAppSubMenu from '@/components/app/OsAppSubMenu.vue';
@Component({
  components: { OsAppSubMenu, OsSubMenuPage, OsSheetContent, OsSheet }
})
export default class Participants extends Vue {}
