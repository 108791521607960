
import { Component, Vue } from 'vue-property-decorator';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsCustomerOverview from '@/components/shared/customers/OsCustomerOverview.vue';
import OsCreateCustomer from '@/components/shared/customers/OsCreateCustomer.vue';
import OsSheetHeader from '@/components/core/sheet/OsSheetHeader.vue';
import OsSearchField from '@/components/core/OsSearchField.vue';
import OsInputCheckbox from '@/components/core/input/OsInputCheckbox.vue';
@Component({
  components: {
    OsInputCheckbox,
    OsSearchField,
    OsSheetHeader,
    OsCreateCustomer,
    OsCustomerOverview,
    OsButton,
    OsSheet,
    OsSheetContent
  }
})
export default class CustomersIndex extends Vue {
  search = '';
  trialOnly = false;
}
