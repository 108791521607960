import { BaseApi } from '@/apis/BaseApi';
import { IBaseApiResponse } from '@/models/core';
import { IAppuser, ICustomer } from '@/models/application';
import { IChangePasswordRequest } from '@/models/authentication/IChangePasswordRequest';
import { IAppuserRole } from '@/models/authentication/IAppuserRole';

export class AppuserApi extends BaseApi {
  constructor() {
    super('appusers');
  }

  async getAllAppusers(internalOnly: boolean): Promise<IAppuser[]> {
    return (await this.get<IAppuser[]>(`?internalOnly=${internalOnly}`)).result;
  }

  async createAppuser(appuser: IAppuser): Promise<IAppuser> {
    appuser.id = '00000000-0000-0000-0000-000000000000';
    return (await this.post<IAppuser>(``, appuser)).result;
  }

  async updateAppuser(appuser: IAppuser): Promise<IAppuser> {
    return (await this.put<IAppuser>(``, appuser)).result;
  }

  async getIsAdmin(appuserId: string): Promise<boolean> {
    return (await this.get<boolean>(`/${appuserId}/roles/isadmin`)).result;
  }

  async setIsAdmin(appuserId: string, value: boolean): Promise<boolean> {
    return (
      await this.post<boolean>(
        `/${appuserId}/roles/isadmin`,
        value,
        true,
        false,
        {
          'Content-Type': 'application/json'
        }
      )
    ).result;
  }

  async setLanguage(
    appuserId: string,
    languageCode: string
  ): Promise<IBaseApiResponse<boolean>> {
    return await this.put<boolean>(
      `/${appuserId}?languageCode=${languageCode}`,
      null,
      true,
      false
    );
  }

  async getById(id: string): Promise<IAppuser> {
    return (await this.get<IAppuser>(`/${id}`)).result;
  }

  async deleteById(id: string): Promise<boolean> {
    return (await this.delete<boolean>(`/${id}`)).result;
  }

  async getRolesByAppuserId(appuserId: string): Promise<IAppuserRole[]> {
    return (await this.get<IAppuserRole[]>(`/${appuserId}/roles`)).result;
  }

  async changePassword(
    appuserId: string,
    request: IChangePasswordRequest
  ): Promise<boolean> {
    const response = await this.post<boolean>(
      `/${appuserId}/changepassword`,
      request,
      true,
      false
    );

    this.showResponseSnackbar(
      response.axiosResponse as any,
      'Password changed successfully.',
      'success',
      ''
    );

    return response.result;
  }

  async getCustomersByAppuserId(appuserId: string): Promise<ICustomer[]> {
    return (await this.get<ICustomer[]>(`/${appuserId}/customers`)).result;
  }

  async addToCustomer(appuserId: string, customerId: string): Promise<boolean> {
    return (await this.post<boolean>(`/${appuserId}/customers/${customerId}`))
      .result;
  }

  async removeFromCustomer(
    appuserId: string,
    customerId: string
  ): Promise<boolean> {
    return (await this.delete<boolean>(`/${appuserId}/customers/${customerId}`))
      .result;
  }
}
