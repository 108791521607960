
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsAppSubMenu from '@/components/app/OsAppSubMenu.vue';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
import { IMenuItem } from '@/models/navigation';
import OsButton from '@/components/core/OsButton.vue';
@Component({
  components: { OsButton, OsSubMenuPage, OsAppSubMenu, OsSheetContent, OsSheet }
})
export default class CampaignById extends Vue {
  customerId!: string;
  campaignId = '';

  get items(): IMenuItem[] {
    return [
      {
        label: 'Back',
        subLabel: 'All your campaigns',
        to: `/customers/${this.customerId}/campaigns`,
        exact: true,
        icon: 'mdi-chevron-left'
      },
      {
        label: 'Campaign',
        subLabel: 'Details and Links',
        to: `/customers/${this.customerId}/campaigns/${this.campaignId}`,
        exact: true
      },
      {
        label: 'Participants',
        subLabel: 'DISC interpretation',
        to: `/customers/${this.customerId}/campaigns/${this.campaignId}/participants`
      },
      {
        label: 'Target profile',
        to: `/customers/${this.customerId}/campaigns/${this.campaignId}/target-profiles`
      },
      {
        label: 'Assigned App Users',
        to: `/customers/${this.customerId}/campaigns/${this.campaignId}/appusers`
      }
    ];
  }

  created() {
    this.customerId = this.$route.params.id;
    this.campaignId = this.$route.params.campaignid;
  }
}
