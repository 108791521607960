
import { Component, Prop, Vue } from 'vue-property-decorator';
import { authenticationApi } from '@/apis';
import OsButton from '@/components/core/OsButton.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import { TokenUtil } from '@/utils/TokenUtil';
import OsSetPasswordForm from '@/components/shared/password/OsSetPasswordForm.vue';
@Component({
  components: {
    OsSetPasswordForm,
    OsSheet,
    OsAlertBox,
    OsButton
  }
})
export default class OsResetPasswordForm extends Vue {
  maxWidth = '450px';
  loading = false;
  tokenIsValid = false;
  info: string | null = null;

  password = '';
  confirmPassword = '';
  token = '';

  @Prop({
    default: 'Set password.'
  })
  textTitle!: string;
  @Prop({
    default: 'Please enter your new password.'
  })
  textSubTitle!: string;
  @Prop({
    default: 'Back to login page.'
  })
  textBackToLoginButton!: string;
  @Prop({
    default:
      'Your Token has been expired. Please go to login page and request a new link.'
  })
  textTokenNotValid!: string;

  async mounted() {
    await this.validateToken();
  }

  async validateToken() {
    this.token = this.$route.params.token;
    this.tokenIsValid = TokenUtil.tokenIsValid(this.token);
    if (!this.tokenIsValid) {
      this.info = this.textTokenNotValid;
    }
  }

  async setPassword() {
    this.info = null;
    this.loading = true;

    try {
      const result = await authenticationApi.resetPassword(
        this.token,
        this.password
      );
      if (result) {
        await this.$router.push('/login');
      }
    } finally {
      this.loading = false;
    }
  }
}
