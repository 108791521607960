
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsTargetProfileForm from '@/components/shared/target-profiles/OsTargetProfileForm.vue';
@Component({
  components: {
    OsSheet,
    OsTargetProfileForm
  }
})
export default class CampaignTargetProfileById extends Vue {}
