import { BaseApi } from '@/apis/BaseApi';
import axios from 'axios';

export class FilesApi extends BaseApi {
  constructor() {
    super('files');
  }

  async uploadCustomerPortalTranslation(
    language: string,
    file: FormData
  ): Promise<string> {
    const response = await this.put<string>(
      `/translations/customerportal/${language}`,
      file,
      true,
      true
    );

    return response.result;
  }

  async getFileContent(url: string): Promise<string> {
    const response = await axios.get<string>(url, { baseURL: '/' });

    return response.data;
  }

  async fileExists(url: string): Promise<boolean> {
    const response = await axios.head(url);

    return response.status != 404;
  }
}
