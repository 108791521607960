
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsAppSubMenu from '@/components/app/OsAppSubMenu.vue';
import { IMenuItem } from '@/models/navigation';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
import { customersApi } from '@/apis';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import { ICustomer } from '@/models/application';
import OsButton from '@/components/core/OsButton.vue';
import OsAdminTrialBanner from '@/components/shared/customers/OsAdminTrialBanner.vue';
import OsCustomerInactiveBanner from '@/components/shared/customers/OsCustomerInactiveBanner.vue';

@Component({
  components: {
    OsCustomerInactiveBanner,
    OsAdminTrialBanner,
    OsButton,
    OsAlertBox,
    OsSubMenuPage,
    OsAppSubMenu,
    OsSheet
  }
})
export default class CustomersIdIndex extends Vue {
  customerId = '';
  customer: ICustomer | null = null;
  get items(): IMenuItem[] {
    let result = [
      {
        label: 'Company profile',
        subLabel: "Company's base data",
        to: `/customers/${this.customerId}/profile`
      },
      {
        label: 'Company settings',
        subLabel: "Company's base settings",
        to: `/customers/${this.customerId}/settings`
      },
      {
        label: 'App Users',
        subLabel: "Company's user",
        to: `/customers/${this.customerId}/appusers`
      },
      {
        label: 'Campaigns',
        subLabel: 'All campaigns',
        to: `/customers/${this.customerId}/campaigns`
      },
      {
        label: 'Participants',
        subLabel: 'All Participants',
        to: `/customers/${this.customerId}/participants`
      }
    ];

    if (this.$global.roles.isAdmin()) {
      result.push({
        label: 'Custom fields',
        subLabel: 'Manage custom Survey fields',
        to: `/customers/${this.customerId}/custom-fields`
      });

      result.push({
        label: 'Internal Users',
        subLabel: 'Assigned Admin Portal users',
        to: `/customers/${this.customerId}/internalusers`
      });
    }

    return result;
  }

  async created() {
    this.customerId = this.$route.params.id;
    this.customer = await customersApi.getCustomerById(this.customerId);
    await this.$store.dispatch('app/updateCustomer', this.customer);
  }

  async destroyed() {
    await this.$store.dispatch('app/updateCustomer', undefined);
  }
}
