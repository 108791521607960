
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import { statisticsApi } from '@/apis';
import { ApexOptions } from 'apexcharts';
import { IKeyValuePair } from '@/models/core';
import moment from 'moment';

@Component({
  components: { OsLoadingIndicator, OsSheetContent, OsSheet }
})
export default class OsStatisticsCardParticipantsPerDay extends Vue {
  value: IKeyValuePair<string, number>[] | null = null;
  ready = false;

  async created() {
    this.value = await statisticsApi.getParticipantsPerDay();

    await this.setSeries();
  }

  series: any = [];

  options: ApexOptions = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: false
        }
      },
      type: 'line'
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    }
  };

  async setSeries() {
    this.ready = false;

    if (!this.value) {
      return;
    }

    this.series = [
      {
        name: 'Participants',
        data: this.value.map((kvp) => {
          return {
            x: moment(kvp.key).add(12, 'hours').toISOString(),
            y: kvp.value
          };
        })
      }
    ];

    this.ready = true;
  }
}
