
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { IQuestion } from '@/models/application/IQuestion';
import OsButton from '@/components/core/OsButton.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import { questionsApi } from '@/apis';
import { supportedLanguages } from '@/lang';

@Component({
  components: { OsInputTextField, OsButton }
})
export default class OsQuestionnaireQuestionLocalizer extends Vue {
  appDirtyUtil = new AppDirtyUtil();

  @PropSync('questions', { required: true })
  internalQuestions!: IQuestion[];

  @Prop({ required: true })
  textHeader!: string;

  @Prop({ default: false })
  readonly!: boolean;

  loading = false;

  get isDirty(): boolean {
    return this.appDirtyUtil.isInstanceDirty;
  }

  get isValid(): boolean {
    return !this.internalQuestions.find(
      (q) => !q.text || q.answeroptions.find((ao) => !ao.text)
    );
  }

  languages = supportedLanguages.map((l) => l.code);
  selectedLanguage = 'en';

  getQuestionLanguagecode(languagecode: string) {
    return this.internalQuestions.find((q) => q.languagecode === languagecode);
  }

  @Watch('internalQuestions', { deep: true })
  onInternalQuestionsChangedDeep() {
    this.appDirtyUtil.update(this.internalQuestions);
  }

  @Watch('isDirty')
  onIsDirtyChanged() {
    this.$emit('update:isDirty', this.isDirty);
  }

  mounted() {
    if (this.internalQuestions) {
      this.internalQuestions.forEach((q) => {
        q.answeroptions = q.answeroptions.sort(
          (a, b) => a.sequencenumber - b.sequencenumber
        );
      });
    }

    this.appDirtyUtil.init(this.internalQuestions);
  }

  async save() {
    this.loading = true;
    try {
      const updatedQuestions: IQuestion[] = [];

      for (const q of this.internalQuestions) {
        const updatedQuestion = await questionsApi.update(q);
        updatedQuestions.push(updatedQuestion);
      }

      this.internalQuestions = updatedQuestions;

      this.appDirtyUtil.init(this.internalQuestions);
    } finally {
      this.loading = false;
    }
  }
}
