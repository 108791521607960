
import { Component, Prop, Vue } from 'vue-property-decorator';
import { customersApi } from '@/apis';
import { AppuserState, IAppuser } from '@/models/application';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
@Component({
  components: { OsDatatable }
})
export default class OsAppusersOverview extends Vue {
  @Prop({ required: true })
  customerId!: string;

  @Prop({ default: 'App users' })
  textTitle!: string;
  @Prop({ default: 'Firstname' })
  textFirstname!: string;
  @Prop({ default: 'Lastname' })
  textLastname!: string;
  @Prop({ default: 'E-Mail-Address' })
  textEmailAddress!: string;
  @Prop({ default: 'State' })
  textState!: string;
  @Prop({ default: 'Active' })
  textAppuserStateActive!: string;
  @Prop({ default: 'Inactive' })
  textAppuserStateInactive!: string;

  header: IExtendedDataTableHeader[] = [
    {
      text: this.textFirstname,
      value: 'firstname'
    },
    {
      text: this.textLastname,
      value: 'lastname'
    },
    {
      text: this.textEmailAddress,
      value: 'emailaddress'
    },
    {
      text: this.textState,
      value: 'state',
      formatter: (v) => {
        switch (v) {
          case AppuserState.Active:
            return this.textAppuserStateActive;
          case AppuserState.Inactive:
            return this.textAppuserStateInactive;
        }

        return '';
      }
    }
  ];
  appusers: IAppuser[] = [];

  async mounted() {
    this.appusers = await customersApi.getAppUsers(this.customerId);
  }
}
