
import { Component, Vue, Watch } from 'vue-property-decorator';
import OsReportForm from '@/components/shared/reports/OsReportForm.vue';
import { IReport } from '@/models/application/IReport';
import { reportsApi } from '@/apis';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import { IReportContent } from '@/models/application/IReportContent';
import OsReportContentEditor from '@/components/shared/reports/OsReportContentEditor.vue';
@Component({
  components: {
    OsReportContentEditor,
    OsLoadingIndicator,
    OsSheet,
    OsReportForm
  }
})
export default class ReportsId extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();
  report: IReport | null = null;
  contents: IReportContent[] = [];

  loading = false;
  reportId!: string;

  @Watch('contents', { deep: true })
  onContentsChanged() {
    setTimeout(() => {
      this.appDirtyUtil.update(this.contents);
    }, 1);
  }

  created() {
    this.reportId = this.$route.params.id;
  }

  async mounted() {
    this.report = await reportsApi.getReportById(this.reportId);
    this.contents = await reportsApi.getReportContentByReportId(this.reportId);

    this.appDirtyUtil.init(this.contents);
  }

  async onSave() {
    this.loading = true;
    const originalContents =
      this.appDirtyUtil.getOriginalObject() as IReportContent[];

    try {
      for (let i = 0; i < this.contents.length; i++) {
        const c = this.contents[i];
        const cOriginal = originalContents.find((o) => o.id === c.id);

        if (c.content === cOriginal?.content) {
          continue;
        }

        this.contents[i] = await reportsApi.updateReportContent(
          this.reportId,
          c
        );
        this.appDirtyUtil.init(this.contents);
      }
    } finally {
      this.loading = false;
    }
  }

  undoClicked() {
    this.contents = this.appDirtyUtil.getOriginalObject();
    this.appDirtyUtil.init(this.contents);
  }
}
