import { RouteConfig } from 'vue-router';
import Statistics from '@/pages/Statistics.vue';
import StatisticsIndex from '@/pages/statistics/Index.vue';

const routeConfig: RouteConfig = {
  path: '/statistics',
  component: Statistics,
  children: [
    {
      path: '',
      component: StatisticsIndex
    }
  ]
};
export default routeConfig;
