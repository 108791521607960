
import { Component, Vue } from 'vue-property-decorator';
import OsAppusersOverview from '@/components/shared/appusers/OsAppusersOverview.vue';
@Component({
  components: { OsAppusersOverview }
})
export default class CustomersAppUsersIndex extends Vue {
  customerId!: string;

  async created() {
    this.customerId = this.$route.params.id;
  }
  openExisting(selected: any) {
    this.$router.push(`/customers/${this.customerId}/appusers/` + selected.id);
  }
  openCreateForm() {
    this.$router.push(`/customers/${this.customerId}/appusers/create`);
  }
}
