
import { Component, Vue } from 'vue-property-decorator';
import OsCampaignForm from '@/components/shared/campaigns/OsCampaignForm.vue';
import {
  ICampaign,
  ILink,
  IRecipient,
  Recipientgroup
} from '@/models/application';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { customersApi } from '@/apis';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import OsAntesDisc from '@/components/chart/OsAntesDisc.vue';
import OsLinksOverview from '@/components/shared/links/OsLinksOverview.vue';
import OsRecipientsOverview from '@/components/shared/recipients/OsRecipientsOverview.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
@Component({
  components: {
    OsRecipientsOverview,
    OsLinksOverview,
    OsAntesDisc,
    OsLoadingIndicator,
    OsSheetContent,
    OsSheet,
    OsCampaignForm
  }
})
export default class CampaignCreate extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  campaign: ICampaign | null = null;

  showCreateDialog = false;
  loading = false;
  customerId!: string;
  campaignId!: string;

  recipients: IRecipient[] = [];

  created() {
    this.customerId = this.$route.params.id;
  }

  async mounted() {
    this.campaignId = this.$route.params.campaignid;
    this.campaign = await customersApi.getCampaignById(
      this.customerId,
      this.campaignId
    );
    this.recipients = await customersApi.getCampaignRecipients(
      this.customerId,
      this.campaignId
    );
  }

  get isRecipientGroupParticipants(): boolean {
    return this.campaign?.recipientgroup === Recipientgroup.Participants;
  }

  async onSave() {
    this.loading = true;
    try {
      this.campaign!.customerId = this.customerId;
      this.campaign = await customersApi.updateCampaign(
        this.customerId,
        this.campaign!
      );
      this.appDirtyUtil.init(this.campaign);
    } finally {
      this.loading = false;
    }
  }

  async onDelete() {
    if (
      await this.$global.dialogs.confirm(
        'Confirm deletion',
        `Are you sure you want to delete the campaign "${
          this.campaign!.title
        }"?<br><br>The Following data will be deleted.<br> <ul><li>Links</li><li>Recipients</li><li>Participants</li><li>Target profiles</li></ul>`
      )
    ) {
      const result = await customersApi.deleteCampaignById(
        this.customerId,
        this.campaign!.id
      );
      if (result) {
        await this.$router.push(`/customers/${this.customerId}/campaigns/`);
      }
    }
  }

  undoClicked() {
    this.campaign = this.appDirtyUtil.getOriginalObject();
    this.appDirtyUtil.init(this.campaign);
  }

  onRecipientsCreate() {
    this.showCreateDialog = true;
  }

  onRecipientCreated(recipient: IRecipient) {
    this.recipients.push(recipient);
    this.showCreateDialog = false;
  }

  async deleteClicked(recipient: IRecipient) {
    if (
      await this.$global.dialogs.confirm(
        'Confirm deletion',
        `Are you sure you want to delete the selected recipient with the e-mail-address '${recipient.emailaddress}'?`
      )
    ) {
      await this.deleteRecipient(recipient);
    }
  }
  async deleteRecipient(recipient: IRecipient) {
    const result = await customersApi.deleteCampaignRecipient(
      this.customerId,
      this.campaignId,
      recipient.id
    );

    if (result) {
      const index = this.recipients.findIndex((r) => r.id === recipient.id);
      this.recipients.splice(index, 1);

      this.$global.dialogs.toast('Recipient successfully deleted.', {
        color: 'success',
        icon: 'mdi-delete-outline'
      });
    } else {
      this.$global.dialogs.toast('Error while deleting recipient.', {
        color: 'error',
        icon: 'mdi-delete-outline'
      });
    }
  }

  onTypeClicked(link: ILink) {
    if (link.participantId) {
      this.$router.push(
        `/customers/${this.customerId}/campaigns/${this.campaignId}/participants/${link.participantId}`
      );
    }
  }

  goToParticipant(id: string) {
    this.$router.push(
      `/customers/${this.customerId}/campaigns/${this.campaignId}/participants/${id}`
    );
  }
}
