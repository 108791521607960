import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { CustomerState, ICustomer } from '@/models/application';

class State {
  isDirty = false;
  selectedCustomerName: string | undefined = '';
  selectedCustomerState: CustomerState | undefined = undefined;
}

const mutations = <MutationTree<State>>{
  setIsDirty(state, payload: boolean) {
    state.isDirty = payload;
  },
  setSelectedCustomerName(state, payload: string) {
    state.selectedCustomerName = payload;
  },
  setSelectedCustomerState(state, payload: CustomerState | undefined) {
    state.selectedCustomerState = payload;
  }
};

const actions = <ActionTree<State, any>>{
  async updateCustomer(store, customer: ICustomer) {
    store.commit('setSelectedCustomerName', customer?.name);
    store.commit('setSelectedCustomerState', customer?.state);
  }
};

const getters = <GetterTree<State, any>>{};

const AppModule = {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AppModule;
