
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { IExtendedAppuser } from '@/models/application';
import { appuserApi } from '@/apis';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import OsAppuserForm from '@/components/shared/appusers/OsAppuserForm.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsInternalUserForm from '@/components/shared/internalusers/OsInternalUserForm.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import OsInternalUsersCustomers from '@/components/shared/internalusers/OsInternalUsersCustomers.vue';
@Component({
  components: {
    OsInternalUsersCustomers,
    OsAlertBox,
    OsInternalUserForm,
    OsAppuserForm,
    OsLoadingIndicator,
    OsSheetContent,
    OsSheet
  }
})
export default class SettingsInternalUserById extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  appuser: IExtendedAppuser | null = null;

  loading = false;

  async mounted() {
    let appuser = (await appuserApi.getById(
      this.$route.params.id
    )) as IExtendedAppuser;
    appuser.isAdmin = await appuserApi.getIsAdmin(appuser.id);

    this.appuser = appuser;

    this.appDirtyUtil.init(this.appuser);
  }

  async onSave() {
    this.loading = true;
    try {
      let updatedAppuser = (await appuserApi.updateAppuser(
        this.appuser!
      )) as IExtendedAppuser;

      if (
        this.appuser?.isAdmin != this.appDirtyUtil.getOriginalObject().isAdmin
      ) {
        updatedAppuser.isAdmin = await appuserApi.setIsAdmin(
          updatedAppuser.id,
          this.appuser!.isAdmin
        );
      }

      this.appuser = updatedAppuser;

      this.appDirtyUtil.init(this.appuser);
    } finally {
      this.loading = false;
    }
  }

  async onDelete() {
    if (
      await this.$global.dialogs.confirm(
        'Confirm deletion',
        `Are you sure you want to delete the internal user ${
          this.appuser!.emailaddress
        }?`
      )
    ) {
      const result = await appuserApi.deleteById(this.appuser!.id);
      if (result) {
        await this.$router.push(`/settings/internalusers`);
      }
    }
  }

  undoClicked() {
    this.appuser = this.appDirtyUtil.getOriginalObject();
    this.appDirtyUtil.init(this.appuser);
  }

  async openCreateNewForm() {
    await this.$router.push(`/settings/internalusers/create`);
  }
}
