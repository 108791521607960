
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsCreateCustomer from '@/components/shared/customers/OsCreateCustomer.vue';
import OsSheetHeader from '@/components/core/sheet/OsSheetHeader.vue';
import OsCloseButton from '@/components/core/OsCloseButton.vue';
@Component({
  components: {
    OsCloseButton,
    OsSheetHeader,
    OsCreateCustomer,
    OsSheetContent,
    OsSheet
  }
})
export default class CustomersCreate extends Vue {}
