
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import { statisticsApi } from '@/apis';
import { ApexOptions } from 'apexcharts';
import { IKeyValuePair } from '@/models/core';

@Component({
  components: { OsLoadingIndicator, OsSheetContent, OsSheet }
})
export default class OsStatisticsCardParticipantsPerDay extends Vue {
  value: IKeyValuePair<string, number>[] | null = null;
  ready = false;

  async created() {
    this.value = await statisticsApi.getParticipantsPerCustomer();

    await this.setSeries();
  }

  series: any = [];

  get options(): ApexOptions {
    return {
      chart: {
        toolbar: {
          show: false
        },
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: false
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        labels: {
          rotate: -45
        },
        tickPlacement: 'on',
        categories: this.value ? this.value?.map((kvp) => kvp.key) : []
      }
    };
  }

  async setSeries() {
    this.ready = false;

    if (!this.value) {
      return;
    }

    this.series = [
      {
        name: 'Participants',
        data: this.value.map((kvp) => kvp.value)
      }
    ];

    this.ready = true;
  }
}
