
import { Component, Vue } from 'vue-property-decorator';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';
import {
  ILoginRequest,
  ILoginResponse,
  LoginResponseType
} from '@/models/authentication';
import OsCard from '@/components/core/card/OsCard.vue';
import OsCardText from '@/components/core/card/OsCardText.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import { authenticationApi } from '@/apis';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import { defaultRoutes } from '@/router/default-routes';

@Component({
  components: {
    OsSheet,
    OsAlertBox,
    OsCardText,
    OsCard,
    OsButton,
    OsInputTextField
  }
})
export default class Login extends Vue {
  loading = false;
  info: string | null = null;
  passwordVisible = false;
  loginRequest: ILoginRequest = {
    emailaddress: '',
    password: ''
  };

  async mounted() {
    const localAuthenticated = this.$store.getters['auth/isAuthenticated'];

    if (localAuthenticated) {
      const serverAuthenticated = await authenticationApi.checkAuthentication();

      if (serverAuthenticated) {
        await this.navigateToPostLoginPage();
      }
    }
  }

  async login() {
    this.info = null;

    if (this.loginRequest.emailaddress === '') {
      this.info = 'Please enter your e-mail-address';
      return;
    }

    if (this.loginRequest.password === '') {
      this.info = 'Please enter your password';
      return;
    }

    this.loading = true;

    this.loginRequest.emailaddress = this.loginRequest.emailaddress.trim();

    try {
      const result: ILoginResponse = await this.$store.dispatch(
        'auth/login',
        this.loginRequest
      );

      if (result.type === LoginResponseType.Success) {
        if (
          !this.$global.roles.isInRole('adminportal_user') &&
          !this.$global.roles.isInRole('adminportal_admin')
        ) {
          this.info = 'E-mail-address or password is incorrect';
          await this.$store.dispatch('auth/logout', this.loginRequest);
          return;
        }

        await this.navigateToPostLoginPage();
        return;
      }

      switch (result.type) {
        case LoginResponseType.EmailNotConfirmed:
          this.info = 'E-Mail not confirmed';
          break;
        case LoginResponseType.UserNotFound:
        case LoginResponseType.WrongPassword:
          this.info = 'E-mail-address or password is incorrect';
          break;
        case LoginResponseType.InvalidUserState:
          this.info = 'User is deactivated';
          break;
        default:
          this.info = 'Error';
          break;
      }
    } finally {
      this.loading = false;
    }
  }

  async navigateToPostLoginPage() {
    if (this.$route.path != defaultRoutes.postLogin) {
      await this.$router.push(defaultRoutes.postLogin);
    }
  }
}
