var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('os-statistics-card-general',{style:({
        height: _vm.cardsHeight
      })})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('os-statistics-card-disc-distribution',{style:({
        height: _vm.cardsHeight
      })})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('os-statistics-card-participants-per-day',{style:({
        height: _vm.cardsHeight
      })})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('os-statistics-card-participants-per-language',{style:({
        height: _vm.cardsHeight
      })})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('os-statistics-card-participants-per-customer',{style:({
        height: _vm.cardsHeight
      })})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }