
import { Component, Prop, Vue } from 'vue-property-decorator';
import { appuserApi } from '@/apis';
import { ICustomer } from '@/models/application';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
import OsSelectCustomerDialog from '@/components/shared/customers/OsSelectCustomerDialog.vue';
@Component({
  components: {
    OsSelectCustomerDialog,
    OsDatatable
  }
})
export default class OsInternalUsersCustomers extends Vue {
  @Prop({ required: true })
  appuserId!: string;

  @Prop({ default: 'Assigned customers' })
  textTitle!: string;

  @Prop({ default: 'Name' })
  textName!: string;

  @Prop({ default: 'Remove Customer' })
  customerRemoveTitle!: string;

  @Prop({ default: 'Would you like to unassign the assigned customer?' })
  customerRemoveText!: string;

  @Prop({ default: 'Customer successfully removed.' })
  customerRemovedSuccessText!: string;

  @Prop({ default: 'Customer is already assigned.' })
  customerAlreadyAssignedText!: string;

  @Prop({ default: 'Customer successfully added.' })
  customerAddedSuccessText!: string;

  @Prop({ default: 'Assign Customer' })
  assignCustomerDialogTitle!: string;

  @Prop({ default: 'Assign Customer' })
  assignCustomerDialogAcceptButtonText!: string;

  @Prop({ default: 'Customer to assign' })
  assignCustomerLookupLabel!: string;

  assignDialogVisible = false;
  assignDialogLoading = false;
  selectedCustomerId: string | null = null;

  header: IExtendedDataTableHeader[] = [
    {
      text: this.textName,
      value: 'name'
    },
    {
      text: '',
      value: 'id',
      icon: 'mdi-link-off',
      width: '15px',
      sortable: false,
      action: async (l: ICustomer) => {
        if (
          await this.$global.dialogs.confirm(
            this.customerRemoveTitle,
            this.customerRemoveText
          )
        ) {
          await this.unassignCustomer(l.id);
        }
      },
      align: 'center'
    }
  ];

  customers: ICustomer[] = [];

  async mounted() {
    await this.loadAssignedCustomers();
  }

  async loadAssignedCustomers() {
    this.customers = await appuserApi.getCustomersByAppuserId(this.appuserId);
  }

  async onCustomerAssignSaved() {
    if (!this.selectedCustomerId) {
      return;
    }

    try {
      this.assignDialogLoading = true;

      await this.assignCustomer(this.selectedCustomerId);

      this.selectedCustomerId = null;
      this.assignDialogVisible = false;
    } finally {
      this.assignDialogLoading = false;
    }
  }

  async unassignCustomer(customerId: string): Promise<boolean> {
    const result = await appuserApi.removeFromCustomer(
      this.appuserId,
      customerId
    );

    if (result) {
      this.$global.dialogs.toast(this.customerRemovedSuccessText, {
        color: 'success',
        icon: 'mdi-link-off'
      });
      await this.loadAssignedCustomers();
    }

    return result;
  }

  async assignCustomer(customerId: string) {
    if (this.customers.find((c) => c.id == customerId)) {
      this.$global.dialogs.toast(this.customerAlreadyAssignedText, {
        color: 'error',
        icon: 'mdi-content-duplicate'
      });
      return;
    }

    const result = await appuserApi.addToCustomer(this.appuserId, customerId);

    if (result) {
      this.$global.dialogs.toast(this.customerAddedSuccessText, {
        color: 'success',
        icon: 'mdi-link'
      });
      await this.loadAssignedCustomers();
    }
  }
}
