
import { Component, Vue } from 'vue-property-decorator';
import OsAppusersOverview from '@/components/shared/appusers/OsAppusersOverview.vue';
import OsParticipantGlobalOverview from '@/components/shared/participants/OsParticipantGlobalOverview.vue';
import { IGlobalSearchParticipant } from '@/models/application';
import { participantsApi } from '@/apis';
@Component({
  components: {
    OsParticipantGlobalOverview,
    OsAppusersOverview
  }
})
export default class CustomersParticipantsIndex extends Vue {
  customerId!: string;
  participants: IGlobalSearchParticipant[] = [];
  loading = false;

  async created() {
    this.customerId = this.$route.params.id;

    this.participants = await participantsApi.getByCustomerId(this.customerId);
  }
  rowClicked(participant: IGlobalSearchParticipant) {
    if (participant) {
      this.$router.push(
        `/customers/${this.customerId}/campaigns/${participant.campaignId}/participants/${participant.id}`
      );
    }
  }
}
