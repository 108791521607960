const messages = {
  api: {
    exception: {
      only_change_own_password: 'You can only change your own password.',
      mandatory_fields_missing: 'Not all mandatory fields were filled.',
      password_incorrect: 'The entered password is incorrect.',
      password_criteria_not_matching: 'The password does not meet criteria.',
      password_not_matching: 'Passwords do not match.',
      token_expired: 'The token has expired.',
      invalid_data: 'The given data was invalid.',
      email_in_use: 'The given emailaddress is already in use.',
      questionnaire_not_found: 'Questionnaire not found.',
      only_one_global_link_allowed:
        'You can not create more than one global link.',
      link_limit_reached:
        'You have reached your link limit and can not create any more links.',
      user_limit_reached:
        'You have reached your user limit and can not create any more users.',
      cannot_delete_own_user: 'You cannot delete your own user.',
      custom_field_has_answers:
        'There are already answers to this question in the system.'
    }
  },
  data: {
    questionnaire: {
      description: {
        antes_profile_standard: 'records natural behavior',
        antes_profile_kombi:
          'records natural and currently adapted behavior in the workplace'
      }
    }
  }
};

export { messages };
