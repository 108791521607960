
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import {
  ISurveyCustomField,
  ISurveyCustomFieldAnswer
} from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import OsButton from '@/components/core/OsButton.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';

@Component({
  components: { OsSheetContent, OsButton, OsEntityForm, OsEntityToolbar }
})
export default class OsParticipantCustomFieldAnswersForm extends Vue {
  @PropSync('value', { required: true })
  internalValue!: ISurveyCustomFieldAnswer[];

  @Prop({ required: true })
  customFields!: ISurveyCustomField[];
  cFieldAnswers: any = {};
  customFieldAnswersReady = false;
  internalIsValid = false;

  get hasCustomFields(): boolean {
    return this.customFields && this.customFields.length > 0;
  }

  get usedCFields(): ISurveyCustomField[] {
    return this.customFields
      .filter(
        (cf) =>
          !!this.internalValue.find((v) => v.surveyCustomFieldId === cf.id)
      )
      .sort((a, b) => (a.sequencenumber = b.sequencenumber));
  }

  get fields(): IEntityFormField[] {
    if (!this.hasCustomFields) {
      return [];
    }

    const fields = this.usedCFields.map((cf) => {
      return {
        type: EntityFormFieldType.text,
        propName: `${cf.id}`,
        required: true,
        label: cf.labels.find((l) => l.languagecode === 'en')?.text,
        xsCols: 12
      } as IEntityFormField;
    });

    return fields ?? [];
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  mounted() {
    this.buildAnswerObject();
  }

  @Watch('internalValue')
  onInternalValueChanged() {
    this.buildAnswerObject();
  }

  @Watch('customFields')
  onCustomFieldsChanged() {
    this.buildAnswerObject();
  }

  @Watch('cFieldAnswers', { deep: true, immediate: true })
  onFieldAnswersChanged() {
    if (!this.customFieldAnswersReady) {
      return;
    }

    if (this.internalValue && this.internalValue.length > 0) {
      this.internalValue.forEach((iv) => {
        const obj = this.cFieldAnswers[`${iv.surveyCustomFieldId}`];
        iv.value = obj ?? '';
      });
    }
  }

  buildAnswerObject() {
    let customFieldAnswersObject: any = {};
    this.customFields.forEach((cf) => {
      customFieldAnswersObject[`${cf.id}`] =
        this.internalValue.find((a) => a.surveyCustomFieldId == cf.id)?.value ??
        '';
    });
    this.cFieldAnswers = customFieldAnswersObject;
    this.customFieldAnswersReady = true;
  }

  @Watch('internalIsValid')
  onInternalIsValidChanged() {
    this.$emit('update:isValid', this.internalIsValid);
  }
}
