import { BaseApi } from '@/apis/BaseApi';
import { ICustomerApiKey } from '@/models/authentication/ICustomerApiKey';

export class CustomerApiKeysApi extends BaseApi {
  constructor() {
    super('CustomerApiKeys');
  }

  async getByCustomerIdAsync(customerId: string): Promise<ICustomerApiKey> {
    return (await this.get<ICustomerApiKey>(`/${customerId}`)).result;
  }

  async createByCustomerIdAsync(customerId: string): Promise<ICustomerApiKey> {
    return (await this.post<ICustomerApiKey>(`/${customerId}`, customerId))
      .result;
  }

  async deleteByCustomerId(customerId: string): Promise<boolean> {
    return (await this.delete<boolean>(`/${customerId}`)).result;
  }
}
