
import { Component, Vue } from 'vue-property-decorator';
import OsAppusersOverview from '@/components/shared/appusers/OsAppusersOverview.vue';
import OsInternalUsersOverview from '@/components/shared/internalusers/OsInternalUsersOverview.vue';
import { IAppuser } from '@/models/application';
@Component({
  components: {
    OsInternalUsersOverview,
    OsAppusersOverview
  }
})
export default class SettingsInternalUserIndex extends Vue {
  openExisting(selected: IAppuser) {
    this.$router.push(`/settings/internalusers/${selected.id}`);
  }
  openCreateForm() {
    this.$router.push(`/settings/internalusers/create`);
  }
}
