import { BaseApi } from '@/apis/BaseApi';
import { IQuestionnaire } from '@/models/application';
import { IQuestion } from '@/models/application/IQuestion';

export class QuestionnairesApi extends BaseApi {
  constructor() {
    super('questionnaires');
  }

  async getAll(): Promise<IQuestionnaire[]> {
    return (await this.get<IQuestionnaire[]>(``)).result;
  }

  async getById(id: string): Promise<IQuestionnaire> {
    return (await this.get<IQuestionnaire>(`/${id}`)).result;
  }

  async getQuestions(questionnaireId: string): Promise<IQuestion[]> {
    return (await this.get<IQuestion[]>(`/${questionnaireId}/questions`))
      .result;
  }
}
