import { GlobalParticipants } from '@/pages';
import { RouteConfig } from 'vue-router';
import GlobalParticipantsIndex from '@/pages/global-participants/Index.vue';

const routeConfig: RouteConfig = {
  path: '/participants',
  component: GlobalParticipants,
  children: [
    {
      path: '',
      component: GlobalParticipantsIndex
    }
  ]
};
export default routeConfig;
