
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { ISurveyCustomField } from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsButton from '@/components/core/OsButton.vue';
import { supportedLanguages } from '@/lang';

@Component({
  components: {
    OsButton,
    OsSheet,
    OsSheetContent,
    OsEntityForm,
    OsEntityToolbar
  }
})
export default class OsCustomFieldForm extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  @Prop({ required: false, default: false })
  isCreateForm!: boolean;

  @PropSync('value', { required: true })
  internalValue!: ISurveyCustomField;

  @Prop({ default: false })
  loading!: boolean;

  languageValues: any = {
    sequencenumber: 0,
    ...supportedLanguages.reduce((a, v) => ({ ...a, [v.code]: '' }), {})
  };

  formIsValid = false;

  get fields(): IEntityFormField[] {
    const fields = supportedLanguages.map((l) => {
      return {
        type: EntityFormFieldType.text,
        required: true,
        smCols: 12,
        label: l.code.toUpperCase(),
        propName: l.code
      };
    });

    if (!this.isCreateForm) {
      fields.unshift({
        type: EntityFormFieldType.number,
        required: true,
        smCols: 12,
        label: 'Sequencenumber',
        propName: 'sequencenumber'
      });
    }

    return fields;
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  onSave() {
    this.$emit('save', {
      value: this.languageValues,
      origin: this.internalValue
    });
  }

  onCancel() {
    this.$emit('cancel');
  }

  mounted() {
    this.onInternalValueChanged();
  }

  @Watch('internalValue', { deep: true })
  onInternalValueChanged() {
    this.languageValues = {
      sequencenumber: this.internalValue.sequencenumber,
      ...supportedLanguages.reduce(
        (a, v) => ({
          ...a,
          [v.code]:
            this.internalValue.labels.find((l) => l.languagecode === v.code)
              ?.text ?? ''
        }),
        {}
      )
    };

    this.appDirtyUtil.init(this.languageValues);
  }
}
