
import { Component, Prop, Vue } from 'vue-property-decorator';
import { customersApi } from '@/apis';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
import {
  ISurveyCustomField,
  ISurveyCustomFieldLabel
} from '@/models/application';
import { supportedLanguages } from '@/lang';
@Component({
  components: { OsDatatable }
})
export default class OsCustomFieldsOverview extends Vue {
  @Prop({ required: true })
  customerId!: string;

  get header(): IExtendedDataTableHeader[] {
    let columns: IExtendedDataTableHeader[] = [
      {
        text: 'Sequencenumber',
        value: 'sequencenumber',
        width: '150px'
      }
    ];

    supportedLanguages.forEach((l) => {
      columns.push({
        text: l.code.toUpperCase(),
        value: 'labels',
        formatter: (v) => this.getLabelByLanguagecode(v, l.code)
      });
    });

    columns.push({
      text: '',
      width: '15px',
      value: 'id',
      sortable: false,
      icon: 'mdi-delete-outline',
      align: 'center',
      action: this.deleteCustomField
    });

    return columns;
  }

  async deleteCustomField(item: ISurveyCustomField) {
    if (
      await this.$global.dialogs.confirm(
        'Confirm deletion',
        `Do you really want to delete the custom survey field "${
          item.labels.find((l) => l.languagecode === 'en')?.text
        }"?`
      )
    ) {
      const result = await customersApi.deleteCustomField(
        this.customerId,
        item.id
      );

      if (result) {
        await this.refresh();
      }
    }
  }

  getLabelByLanguagecode(v: ISurveyCustomFieldLabel[], lang: string) {
    if (!v) {
      return '-';
    }

    return v.find((l) => l.languagecode === lang)?.text ?? '-';
  }

  customFields: any[] = [];

  async mounted() {
    this.customFields = await customersApi.getCustomFields(this.customerId);
  }

  async refresh() {
    this.customFields = await customersApi.getCustomFields(this.customerId);
  }
}
