const supportedLanguages = [
  {
    name: 'English',
    code: 'en'
  },
  {
    name: 'German',
    code: 'de'
  },
  {
    name: 'Danish',
    code: 'da'
  },
  {
    name: 'Spanish',
    code: 'es'
  },
  {
    name: 'French',
    code: 'fr'
  },
  {
    name: 'Turkish',
    code: 'tr'
  },
  {
    name: 'Russian',
    code: 'ru'
  },
  {
    name: 'Chinese',
    code: 'cn'
  }
];

const visibleLanguages = [
  {
    name: 'English',
    code: 'en'
  },
  {
    name: 'German',
    code: 'de'
  },
  {
    name: 'Danish',
    code: 'da'
  }
];

export { supportedLanguages, visibleLanguages };
