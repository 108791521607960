
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsAppSubMenu from '@/components/app/OsAppSubMenu.vue';
import { IMenuItem } from '@/models/navigation';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
@Component({
  components: { OsSubMenuPage, OsAppSubMenu, OsSheet }
})
export default class SettingsIndex extends Vue {
  customerId = '';
  get items(): IMenuItem[] {
    return [
      {
        label: 'Internal users',
        subLabel: 'Manage internal users',
        to: `/settings/internalusers`
      },
      {
        label: 'Reports',
        subLabel: 'Manage report content',
        to: `/settings/reports`
      },
      {
        label: 'Questionnaires',
        subLabel: 'Manage Questionnaire content',
        to: `/settings/questionnaires`
      },
      {
        label: 'Translations',
        subLabel: 'Manage customer portal translations',
        to: `/settings/translations`
      }
    ];
  }
}
