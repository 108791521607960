
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { IGlobalSearchParticipant } from '@/models/application';
import { participantsApi } from '@/apis';
import OsParticipantGlobalOverview from '@/components/shared/participants/OsParticipantGlobalOverview.vue';
@Component({
  components: {
    OsParticipantGlobalOverview,
    OsSheetContent,
    OsSheet
  }
})
export default class GlobalParticipantsIndex extends Vue {
  participants: IGlobalSearchParticipant[] = [];
  loading = false;

  async created() {
    this.loading = true;
    this.participants = await participantsApi.getParticipants();
    this.loading = false;
  }

  rowClicked(selected: IGlobalSearchParticipant) {
    this.$router.push(
      `/customers/${selected.customerid}/campaigns/${selected.campaignId}/participants/${selected.id}`
    );
  }
}
