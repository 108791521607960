import { AuthenticationApi } from '@/apis/AuthenticationApi';
import { CustomersApi } from '@/apis/CustomersApi';
import { AppuserApi } from '@/apis/AppuserApi';
import { MetadataApi } from '@/apis/MetadataApi';
import { ParticipantsApi } from '@/apis/ParticipantsApi';
import { ReportsApi } from '@/apis/ReportsApi';
import { QuestionnairesApi } from '@/apis/QuestionnairesApi';
import { QuestionsApi } from '@/apis/QuestionsApi';
import { RegistrationApi } from '@/apis/RegistrationApi';
import { CustomerApiKeysApi } from '@/apis/CustomerApiKeysApi';
import { StatisticsApi } from '@/apis/StatisticsApi';
import { FilesApi } from '@/apis/FilesApi';

const customersApi: CustomersApi = new CustomersApi();
const authenticationApi: AuthenticationApi = new AuthenticationApi();
const reportsApi: ReportsApi = new ReportsApi();
const appuserApi: AppuserApi = new AppuserApi();
const metadataApi: MetadataApi = new MetadataApi();
const participantsApi: ParticipantsApi = new ParticipantsApi();
const questionnairesApi: QuestionnairesApi = new QuestionnairesApi();
const questionsApi: QuestionsApi = new QuestionsApi();
const registrationApi: RegistrationApi = new RegistrationApi();
const customerApiKeysApi: CustomerApiKeysApi = new CustomerApiKeysApi();
const statisticsApi: StatisticsApi = new StatisticsApi();
const filesApi: FilesApi = new FilesApi();

export {
  authenticationApi,
  customersApi,
  reportsApi,
  appuserApi,
  metadataApi,
  participantsApi,
  questionnairesApi,
  questionsApi,
  registrationApi,
  customerApiKeysApi,
  statisticsApi,
  filesApi
};
