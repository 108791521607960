import moment from 'moment';

export class FormatterUtil {
  static boolToHumanStringFormatter(v: boolean): string {
    return v ? 'yes' : 'no';
  }

  static dateTimeFormatted(v: string, f = 'DD.MM.YY'): string {
    return moment(v).format(f);
  }
}
