
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { IQuestionnaire } from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';

@Component({
  components: { OsSheetContent, OsEntityForm, OsEntityToolbar }
})
export default class OsQuestionnairesForm extends Vue {
  @PropSync('appDirtyUtil', { required: true })
  internalAppDirtyUtil!: AppDirtyUtil;

  @PropSync('value', { required: true })
  internalValue!: IQuestionnaire;
  loading = false;
  formIsValid = false;

  get fields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.text,
        label: 'Title',
        propName: 'title',
        smCols: 12,
        readonly: true
      }
      // {
      //   type: EntityFormFieldType.textarea,
      //   label: 'Description',
      //   propName: 'description',
      //   smCols: 12,
      //   readonly: true
      // }
    ];
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  onSave() {
    this.$emit('onSave');
  }

  onDelete() {
    this.$emit('onDelete');
  }

  onUndo() {
    this.$emit('undoClicked');
  }

  openCreateNewForm() {
    this.$emit('openCreateNewForm');
  }

  mounted() {
    this.internalAppDirtyUtil.init(this.internalValue);
  }

  @Watch('internalValue', { deep: true })
  onInternalValueChanged() {
    this.internalAppDirtyUtil.update(this.internalValue);
  }
}
