
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { CustomerState, ICustomer } from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsLogo from '@/components/core/OsLogo.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';

@Component({
  components: {
    OsToolbar,
    OsLogo,
    OsSheetContent,
    OsEntityForm,
    OsEntityToolbar
  }
})
export default class OsCustomerForm extends Vue {
  @PropSync('appDirtyUtil', { required: true })
  internalAppDirtyUtil!: AppDirtyUtil;

  @PropSync('value', { required: true })
  internalValue!: ICustomer;

  @PropSync('loading', { default: false })
  internalLoading!: boolean;

  @Prop({ default: false, required: false })
  readonly!: boolean;

  @Prop({ default: 'Company profile' })
  title!: string;

  @Prop({ default: '' })
  textState!: string;
  @Prop({ default: 'Active' })
  textCustomerStateActive!: string;
  @Prop({ default: 'Inactive' })
  textCustomerStateInactive!: string;

  @Prop({ default: 'Name' })
  textName!: string;

  @Prop({ default: 'Report cover information' })
  textReportCoverFieldsTitle!: string;

  customerFormCustomerInfoIsValid = false;
  customerFormReportCoverInfoIsValid = false;

  get customerInfoFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.radio,
        label: this.textState,
        propName: 'state',
        smCols: 12,
        required: true,
        radioAsRow: true,
        readonly: !this.$global.roles.isAdmin(),
        items: [
          { key: this.textCustomerStateActive, value: CustomerState.Active },
          {
            key: this.textCustomerStateInactive,
            value: CustomerState.Inactive
          }
        ]
      },
      {
        type: EntityFormFieldType.text,
        label: 'Name',
        propName: 'name',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: 'Branch',
        propName: 'branch',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: 'Primary contact name',
        propName: 'primarycontactname',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: 'Primary contact e-mail',
        propName: 'primarycontactemailaddress',
        smCols: 12,
        mdCols: 4,
        required: true
      }
    ];
  }
  get customerReportCoverFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.text,
        label: 'Street',
        propName: 'street',
        smCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: 'City',
        propName: 'city',
        smCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: 'Telephone',
        propName: 'telephone',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: 'E-Mail',
        propName: 'emailaddress',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: 'Website',
        propName: 'website',
        smCols: 12,
        mdCols: 4,
        required: true
      }
    ];
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  onSave() {
    this.$emit('onSave');
  }

  onUndo() {
    this.$emit('undoClicked');
  }

  @Watch('internalValue', { deep: true })
  onInternalValueChanged() {
    this.internalAppDirtyUtil.update(this.internalValue);
  }
}
