import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {
  ILoginRequest,
  ILoginResponse,
  LoginResponseType
} from '@/models/authentication';
import { authenticationApi } from '@/apis';

class State {
  userId: string | null = null;
  username: string | null = null;
  accessToken: string | null = null;
  refreshToken: string | null = null;
}

const mutations = <MutationTree<State>>{
  setUser(state, payload: { userId: string; username: string }) {
    state.userId = payload.userId;

    if (payload.username) {
      state.username = payload.username;
    }
  },
  setTokens(
    state,
    payload: {
      accessToken: string;
      refreshToken: string | undefined;
    }
  ) {
    state.accessToken = payload.accessToken;

    if (payload.refreshToken) {
      state.refreshToken = payload.refreshToken;
    }
  },
  clearUserData(state) {
    state.userId = null;
    state.accessToken = null;
    state.refreshToken = null;
    state.username = null;
  }
};

const actions = <ActionTree<State, any>>{
  async login(
    store,
    loginRequest: ILoginRequest
  ): Promise<ILoginResponse | null> {
    try {
      const loginResponse = await authenticationApi.login(loginRequest);

      if (loginResponse.type === LoginResponseType.Success) {
        store.commit('setUser', {
          userId: loginResponse.userId,
          username: loginRequest.emailaddress
        });

        store.commit('setTokens', {
          accessToken: loginResponse.accessToken,
          refreshToken: loginResponse.refreshToken
        });
      } else {
        await store.dispatch('logout');
      }

      return loginResponse;
    } catch (ex) {
      console.log(ex);
      return null;
    }
  },
  async logout(store) {
    const token = store.state.refreshToken;

    store.commit('clearUserData');
    store.commit('user/setAppUser', null, {
      root: true
    });

    if (token) {
      await authenticationApi.logout(token);
    }
  },
  async refresh(store): Promise<ILoginResponse | null> {
    try {
      if (store.state.refreshToken == null) {
        return null;
      }
      const loginResponse = await authenticationApi.refreshToken(
        store.state.refreshToken
      );

      if (loginResponse.type === LoginResponseType.Success) {
        store.commit('setUser', {
          userId: loginResponse.userId
        });
        store.commit('setTokens', {
          accessToken: loginResponse.accessToken,
          refreshToken: loginResponse.refreshToken
        });
      } else {
        await store.dispatch('logout');
      }

      return loginResponse;
    } catch (e) {
      console.log(e);
      await store.dispatch('logout');
      return null;
    }
  }
};

const getters = <GetterTree<State, any>>{
  isAuthenticated(state): boolean {
    return state.accessToken != null;
  },
  accessToken(state): string | null {
    return state.accessToken;
  }
};

const AuthModule = {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AuthModule;
