
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { IGeneralStatisticData } from '@/models/application/IGeneralStatisticData';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import { statisticsApi } from '@/apis';
import { ApexOptions } from 'apexcharts';
import OsSheetHeader from '@/components/core/sheet/OsSheetHeader.vue';

@Component({
  components: { OsSheetHeader, OsLoadingIndicator, OsSheetContent, OsSheet }
})
export default class OsStatisticsCardGeneral extends Vue {
  value: IGeneralStatisticData | null = null;
  ready = false;

  async created() {
    this.value = await statisticsApi.getGeneralStatistics();

    await this.setSeries();
  }

  series: any = [];

  options: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      },
      type: 'bar'
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true
      }
    },
    dataLabels: {
      enabled: true
    },
    xaxis: {
      categories: ['Customers', 'Users', 'Campaigns', 'Links', 'Participants']
    }
  };

  async setSeries() {
    this.ready = false;

    if (!this.value) {
      return;
    }

    this.series = [
      {
        name: 'Count',
        data: [
          this.value.customerCount,
          this.value.userCount,
          this.value.campaignCount,
          this.value.linkCount,
          this.value.participantCount
        ]
      }
    ];

    this.ready = true;
  }
}
