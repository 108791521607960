
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { ICustomer, IExtendedAppuser } from '@/models/application';
import { appuserApi, customersApi } from '@/apis';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import OsAppuserForm from '@/components/shared/appusers/OsAppuserForm.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import OsAppUsersCampaigns from '@/components/shared/appusers/OsAppUsersCampaigns.vue';
@Component({
  components: {
    OsAppUsersCampaigns,
    OsAlertBox,
    OsAppuserForm,
    OsLoadingIndicator,
    OsSheetContent,
    OsSheet
  }
})
export default class CustomersAppUsersById extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  appuser: IExtendedAppuser | null = null;

  loading = false;
  customerId!: string;
  customer: ICustomer | null = null;

  async created() {
    this.customerId = this.$route.params.id;
    this.customer = await customersApi.getCustomerById(this.customerId);
  }

  async mounted() {
    const appuserId = this.$route.params.appuserId;
    let appuser = (await customersApi.getAppUserById(
      this.$route.params.id,
      appuserId
    )) as IExtendedAppuser;
    appuser.isAdmin = await customersApi.appUserIsAdmin(
      this.customerId,
      appuser.id
    );

    this.appuser = appuser;
  }

  async onSave() {
    this.loading = true;
    try {
      this.appuser!.customerId = this.customerId;

      let updatedAppuser = (await customersApi.updateAppuser(
        this.customerId,
        this.appuser!
      )) as IExtendedAppuser;

      if (
        this.appuser?.isAdmin != this.appDirtyUtil.getOriginalObject().isAdmin
      ) {
        updatedAppuser.isAdmin = await appuserApi.setIsAdmin(
          updatedAppuser.id,
          this.appuser!.isAdmin
        );
      }

      this.appuser = updatedAppuser;

      this.appDirtyUtil.init(this.appuser);
    } finally {
      this.loading = false;
    }
  }

  async onDelete() {
    if (
      await this.$global.dialogs.confirm(
        'Confirm deletion',
        `Are you sure you want to delete the appuser ${
          this.appuser!.emailaddress
        }?`
      )
    ) {
      const result = await customersApi.deleteAppuserById(
        this.customerId,
        this.appuser!.id
      );
      if (result) {
        await this.$router.push(`/customers/${this.customerId}/appusers`);
      }
    }
  }

  undoClicked() {
    this.appuser = this.appDirtyUtil.getOriginalObject();
    this.appDirtyUtil.init(this.appuser);
  }

  async openCreateNewForm() {
    await this.$router.push(`/customers/${this.customerId}/appusers/create`);
  }
}
