
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsAntesDiscEditor from '@/components/chart/OsAntesDiscEditor.vue';
import OsParticipantOverview from '@/components/shared/participants/OsParticipantOverview.vue';
import OsAntesDiscEditorValidator from '@/components/chart/OsAntesDiscEditorValidator.vue';
import { IParticipant } from '@/models/application';
import { customersApi, reportsApi } from '@/apis';
import { IReportContent } from '@/models/application/IReportContent';
import OsButton from '@/components/core/OsButton.vue';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import { ITargetProfile } from '@/models/application/ITargetProfile';
import { ConfigUtil } from '@/utils/ConfigUtil';
import OsTargetProfilePdfDownloadBtn from '@/components/shared/target-profiles/OsTargetProfilePdfDownloadBtn.vue';
@Component({
  components: {
    OsTargetProfilePdfDownloadBtn,
    OsInputTextField,
    OsEntityToolbar,
    OsButton,
    OsParticipantOverview,
    OsAntesDiscEditor,
    OsAntesDiscEditorValidator,
    OsSheet,
    OsSheetContent
  }
})
export default class OsTargetProfileForm extends Vue {
  @Prop({ default: 'Target profile' })
  title!: string;

  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();
  targetProfileId = '';
  targetProfile: ITargetProfile | null = null;
  downloading = false;
  reportContent: IReportContent | null = null;
  participants: IParticipant[] = [];
  profile = '';
  loading = false;
  dragging = false;
  discIsValid = false;
  customerId!: string;
  campaignId!: string;

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  buildExternalLinkUrl(token: string) {
    return `${
      ConfigUtil.get().surveyBaseUrl
    }/external/target-profile?token=${token}`;
  }

  get targetReportText(): string {
    if (this.reportContent === null) {
      return '';
    }

    let text = this.reportContent.content;

    if (this.targetProfile?.name != null) {
      text = text.replace(new RegExp('%Stelle%', 'g'), this.targetProfile.name);
    }

    return text;
  }

  async copyExternalLink() {
    if (!this.targetProfile?.externalToken) {
      return;
    }

    const link = this.buildExternalLinkUrl(this.targetProfile?.externalToken);
    const result = await this.$copyText(link);

    if (link === result.text) {
      this.$global.dialogs.toast('copied', {
        color: 'success',
        icon: 'mdi-content-copy'
      });
    }
  }

  async created() {
    this.customerId = this.$route.params.id;
    this.campaignId = this.$route.params.campaignid;
    this.targetProfileId = this.$route.params.targetprofileid;

    this.targetProfile = await customersApi.getTargetProfileById(
      this.customerId,
      this.campaignId,
      this.targetProfileId
    );

    this.appDirtyUtil.init(this.targetProfile);
  }

  async undoClicked() {
    this.targetProfile = this.appDirtyUtil.getOriginalObject();
    this.appDirtyUtil.init(this.targetProfile);
    await this.onChange();
  }

  async saveClicked() {
    this.targetProfile = await customersApi.updateTargetProfile(
      this.customerId,
      this.targetProfile!
    );
    this.appDirtyUtil.init(this.targetProfile);
  }

  async deleteClicked() {
    if (
      await this.$global.dialogs.confirm(
        'Delete Target Profile?',
        `Do you really want to delete the target profile '${this.targetProfile?.name}'`
      )
    ) {
      if (
        await customersApi.deleteTargetProfile(
          this.customerId,
          this.targetProfileId
        )
      ) {
        await this.$router.push(
          `/customers/${this.customerId}/campaigns/${this.campaignId}/target-profiles`
        );
      }
    }
  }

  @Watch('targetProfile', { deep: true })
  onTargetProfileChanged() {
    this.appDirtyUtil.update(this.targetProfile);
  }

  async renderPdf() {
    if (this.targetProfile === null) {
      return;
    }

    this.downloading = true;
    let filename = `Target_Profile_${this.profile}.pdf`;

    if (this.profile) {
      await reportsApi.renderTargetProfile(
        filename,
        this.profile,
        this.targetProfile.name,
        'en',
        this.targetProfile
      );
    }
    this.downloading = false;
  }

  @Watch('discIsValid')
  async onChange() {
    if (!this.discIsValid) {
      this.reportContent = null;
      this.participants = [];
    }

    if (
      this.targetProfile === null ||
      this.targetProfile.dValue == null ||
      this.targetProfile.iValue == null ||
      this.targetProfile.sValue == null ||
      this.targetProfile.cValue == null
    ) {
      return;
    }

    if (!this.dragging && this.discIsValid) {
      this.loading = true;
      this.participants = [];
      this.participants = await customersApi.getForCampaignByDISCValuesAsync(
        this.customerId,
        this.campaignId,
        this.targetProfile.dValue,
        this.targetProfile.iValue,
        this.targetProfile.sValue,
        this.targetProfile.cValue
      );

      if (this.profile) {
        this.reportContent = await reportsApi.getTargetProfileContent(
          this.profile,
          'en'
        );
      }

      this.loading = false;
    } else {
      this.participants = [];
    }
  }
}
