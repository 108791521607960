
import { Component, Vue } from 'vue-property-decorator';
import OsAppBar from '@/components/app/OsAppBar.vue';
import OsGlobalDialogs from '@/components/app/OsGlobalDialogs.vue';
import { JwtUtil } from '@/utils/JwtUtil';

@Component({
  components: { OsGlobalDialogs, OsAppBar }
})
export default class DefaultLayout extends Vue {
  appIsReady = false;

  async mounted() {
    const decodedJwtPayload = JwtUtil.getDecodedPayload();

    await this.$store.dispatch('user/fetchLoggedInUserData', {
      appuserId: decodedJwtPayload?.AppUserId
    });
    this.appIsReady = true;
  }
}
