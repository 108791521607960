
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { DatePickerType } from '@/models/core';

@Component
export default class OsInputDatePicker extends Vue {
  @Prop({ required: true, default: '' })
  label!: string;

  @Prop({ required: false, default: false })
  readonly!: string;

  @Prop({ required: true, default: null })
  value!: string | null;

  @Prop({ required: false, default: false })
  required!: boolean;

  @Prop({ required: false, default: DatePickerType.day })
  type!: DatePickerType;

  @Prop({ required: false, default: undefined })
  min!: string;

  @Prop({ required: false, default: undefined })
  max!: string;

  isOpened = false;
  internalValue: string | null = null;

  get localizedValue(): string {
    if (this.internalValue === null) {
      return '';
    }

    return moment(this.internalValue).locale('de-DE').format('DD.MM.YYYY');
  }

  @Watch('value')
  onValueChanged() {
    this.internalValue = this.value;
  }

  @Watch('internalValue')
  onInternalValueChanged() {
    this.internalValue = this.getModeDependingTransformedDate(
      this.internalValue
    );

    this.$emit('input', this.internalValue);
  }

  mounted() {
    this.internalValue = this.value;
  }

  getModeDependingTransformedDate(v: string | null): string | null {
    if (v === null) {
      return null;
    }
    return moment(v).format('YYYY-MM-DD');
  }

  get rules(): any {
    const rules = [];

    if (this.required) {
      rules.push((v: string) => !!v || 'required field');
    }

    return rules;
  }
}
