
import { Component, Vue } from 'vue-property-decorator';
import OsReportsOverview from '@/components/shared/reports/OsReportsOverview.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsTranslationFileUploader from '@/components/core/OsTranslationFileUploader.vue';
import OsButton from '@/components/core/OsButton.vue';
import { ConfigUtil } from '@/utils/ConfigUtil';
import { filesApi } from '@/apis';
import moment from 'moment/moment';
import { supportedLanguages } from '@/lang';

@Component({
  components: {
    OsButton,
    OsTranslationFileUploader,
    OsSheetContent,
    OsSheet,
    OsReportsOverview
  }
})
export default class TranslationsIndex extends Vue {
  openDebugMode() {
    window.open(`${ConfigUtil.get().surveyBaseUrl}?lang=debug`);
  }

  languages = supportedLanguages.map((l) => l.code);

  async downloadEmptyTranslation() {
    let emptyTranslations = await filesApi.getFileContent(
      '/lang/customer_portal_empty.json'
    );

    let translationAsString = JSON.stringify(emptyTranslations, null, 4);

    this.openDownloadDialog(
      translationAsString,
      `empty-${moment().format('MM_DD_yy-hh-mm')}.json`
    );
  }

  openDownloadDialog(content: string, filename: string) {
    let element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:application/plain;charset=utf-8,' + encodeURIComponent(content)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
