
import { Component, Prop, Vue } from 'vue-property-decorator';
import { reportsApi } from '@/apis';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
import { IReport } from '@/models/application/IReport';
import { ReportType } from '@/models/application';
import OsCard from '@/components/core/card/OsCard.vue';
import OsCardText from '@/components/core/card/OsCardText.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsTestReportForm from '@/components/shared/reports/OsTestReportForm.vue';
import { supportedLanguages } from '@/lang';

@Component({
  components: {
    OsTestReportForm,
    OsSheetContent,
    OsSheet,
    OsCardText,
    OsCard,
    OsDatatable
  }
})
export default class OsReportsOverview extends Vue {
  $refs!: {
    osTestReport: OsTestReportForm;
  };

  @Prop({ default: 'Reports' })
  textHeader!: string;

  showTestDialog = false;
  reports: IReport[] = [];

  get header(): IExtendedDataTableHeader[] {
    let columns: IExtendedDataTableHeader[] = [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Type',
        width: '100px',
        value: 'type'
      }
    ];

    supportedLanguages.forEach((l) => {
      columns.push({
        icon: 'mdi-file-document',
        width: '55px',
        text: l.code.toUpperCase(),
        value: 'type',
        align: 'center',
        sortable: false,
        action: (r: IReport) => {
          this.downloadReport(r, l.code);
        }
      });
    });

    return columns;
  }

  async mounted() {
    this.reports = await reportsApi.getReports();
  }

  downloadReport(r: IReport, language: string) {
    if (r.type === ReportType.Actual) {
      this.openTestDialog();

      setTimeout(() => {
        this.$refs.osTestReport.languagecode = language;
        this.$refs.osTestReport.participant.dValue = -1;
        this.$refs.osTestReport.participant.iValue = -1;
        this.$refs.osTestReport.participant.sValue = -1;
        this.$refs.osTestReport.participant.cValue = -1;

        let counter = 4;
        for (let i = 0; i < r.name.length; i++) {
          const char = r.name.charAt(i);

          (this.$refs.osTestReport.participant as any)[
            `${char.toLowerCase()}Value`
          ] = counter;

          counter--;
        }

        this.openTestDialog();
      }, 100);
    } else {
      reportsApi.renderTargetProfile(
        `Targetprofile_${r.name}.pdf`,
        r.name,
        'JOB TITLE PLACEHOLDER',
        language
      );
    }
  }

  openTestDialog() {
    this.showTestDialog = true;
  }

  closeTestDialog() {
    this.showTestDialog = false;
  }
}
