import { BaseApi } from '@/apis/BaseApi';
import { IQuestion } from '@/models/application/IQuestion';

export class QuestionsApi extends BaseApi {
  constructor() {
    super('questions');
  }

  async update(question: IQuestion): Promise<IQuestion> {
    return (await this.put<IQuestion>(``, question)).result;
  }
}
