import Vue from 'vue';
import OsConfirmDialog from '@/components/core/OsConfirmDialog.vue';
import OsInformDialog from '@/components/core/OsInformDialog.vue';
import { IGlobalFunctions } from '@/types/IGlobalFunctions';
import OsSnackBar from '@/components/core/OsSnackBar.vue';
import OsBusinessErrorResponseDialog from '@/components/core/OsBusinessErrorDialog.vue';
import { IHandledExceptionResponse } from '@/models/core/IHandledExceptionResponse';
import { JwtUtil } from '@/utils/JwtUtil';

const global: IGlobalFunctions = {
  dialogs: {
    setConfirmDialog: (d: OsConfirmDialog) => {
      (Vue.prototype.$global as IGlobalFunctions).dialogs.confirmDialogRef = d;
    },
    setInfoDialog: (d: OsInformDialog) => {
      (Vue.prototype.$global as IGlobalFunctions).dialogs.infoDialogRef = d;
    },
    setSnackBar: (sb: OsSnackBar) => {
      (Vue.prototype.$global as IGlobalFunctions).dialogs.setSnackBarRef = sb;
    },
    setBusinessErrorDialogRef: (d: OsBusinessErrorResponseDialog) => {
      (
        Vue.prototype.$global as IGlobalFunctions
      ).dialogs.businessErrorDialogRef = d;
    },
    confirm: async (
      title = '',
      message = '',
      options: any = {}
    ): Promise<boolean> => {
      return await (
        Vue.prototype.$global as IGlobalFunctions
      ).dialogs.confirmDialogRef!.open(title, message, options);
    },
    toast: (
      text: string,
      opt?: {
        color: string;
        icon: string;
      }
    ) => {
      return (
        Vue.prototype.$global as IGlobalFunctions
      ).dialogs.setSnackBarRef!.show(text, opt);
    },
    info: async (title = '', body = ''): Promise<boolean> => {
      return await (
        Vue.prototype.$global as IGlobalFunctions
      ).dialogs.infoDialogRef!.open(title, body);
    },
    businessError: (r: IHandledExceptionResponse) => {
      (
        Vue.prototype.$global as IGlobalFunctions
      ).dialogs.businessErrorDialogRef!.open(r);
    }
  },

  roles: {
    isInRole: (r: string | string[]): boolean => {
      const jwtPayload = JwtUtil.getDecodedPayload();

      if (jwtPayload !== undefined && jwtPayload !== null) {
        const roles = (jwtPayload as any)[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ] as string[];

        if (Array.isArray(r)) {
          return r.some((v) => roles.includes(v));
        }

        return roles.includes(r);
      }

      return false;
    },
    isAdmin: (): boolean => {
      return global.roles.isInRole('adminportal_admin');
    }
  }
};

Vue.prototype.$global = global;
