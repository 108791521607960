
import { Component, Vue } from 'vue-property-decorator';
import { IMenuItem } from '@/models/navigation';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsProfileIconPopover from '@/components/app/OsProfileIconPopover.vue';
import OsGlobalSearch from '@/components/app/OsGlobalSearch.vue';
@Component({
  components: {
    OsGlobalSearch,
    OsProfileIconPopover,
    OsButton,
    OsInputTextField
  }
})
export default class OsAppBar extends Vue {
  pageScrollThreshold = 20;
  pageIsScrolled = false;

  get height(): string {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return '60px';
    }

    if (this.$vuetify.breakpoint.smAndUp) {
      return '120px';
    }

    return '180px';
  }
  get leftMenuItems(): IMenuItem[] {
    if (this.$global.roles.isAdmin()) {
      return [
        {
          label: 'Statistics',
          to: '/statistics'
        },
        {
          label: 'Customers',
          to: '/customers'
        },
        {
          label: 'Settings',
          to: '/settings'
        }
      ];
    }

    return [
      {
        label: 'Customers',
        to: '/customers'
      }
    ];
  }

  rightMenuItems: IMenuItem[] = [
    {
      label: 'Global Participant Search',
      to: '/participants'
    }
  ];

  mounted() {
    window.onscroll = () => {
      this.updateScrollState();
    };
    this.updateScrollState();
  }

  updateScrollState() {
    this.pageIsScrolled =
      document.body.scrollTop > this.pageScrollThreshold ||
      document.documentElement.scrollTop > this.pageScrollThreshold;
  }
}
