
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { IQuestionnaire } from '@/models/application';
import { questionnairesApi } from '@/apis';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import OsAppuserForm from '@/components/shared/appusers/OsAppuserForm.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsInternalUserForm from '@/components/shared/internalusers/OsInternalUserForm.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import OsQuestionnairesForm from '@/components/shared/questionnairs/OsQuestionnairesForm.vue';
import { IQuestion } from '@/models/application/IQuestion';
import OsSheetHeader from '@/components/core/sheet/OsSheetHeader.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsQuestionnaireQuestionLocalizer from '@/components/shared/questionnairs/question/OsQuestionnaireQuestionLocalizer.vue';
@Component({
  components: {
    OsQuestionnaireQuestionLocalizer,
    OsButton,
    OsSheetHeader,
    OsQuestionnairesForm,
    OsAlertBox,
    OsInternalUserForm,
    OsAppuserForm,
    OsLoadingIndicator,
    OsSheetContent,
    OsSheet
  }
})
export default class SettingsQuestionnairesById extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  questionnaire: IQuestionnaire | null = null;
  questions: IQuestion[] = [];

  dirtySequenceNumber: number | null = null;
  loading = false;

  get uniqueSequenceNumbers(): number[] {
    return [
      ...new Set(
        this.questions.map((q) => q.sequencenumber).sort((a, b) => a - b)
      )
    ];
  }

  getQuestionBySequenceNumber(sn: number) {
    return this.questions.filter((q) => q.sequencenumber === sn);
  }

  async mounted() {
    this.questionnaire = await questionnairesApi.getById(this.$route.params.id);
    this.questions = await questionnairesApi.getQuestions(
      this.questionnaire.id
    );
  }
}
