
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import OsButton from '@/components/core/OsButton.vue';
import { CustomerState, ICustomer } from '@/models/application';
import { customersApi } from '@/apis';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsToolbar from '@/components/core/OsToolbar.vue';

@Component({
  components: { OsToolbar, OsButton, OsEntityForm }
})
export default class OsCreateCustomer extends Vue {
  customerAppDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  @Prop({ default: 'create customer' })
  textCreateCustomer!: string;
  @Prop({ default: 'Report cover information' })
  textReportCoverFieldsTitle!: string;

  internalCustomerValue: ICustomer = {
    state: CustomerState.Active,
    id: '',
    name: '',
    street: '',
    city: '',
    logourl: '',
    primarycontactname: '',
    primarycontactemailaddress: '',
    branch: '',
    emailaddress: '',
    telephone: '',
    website: '',
    trialEnddate: null
  };
  customerFormCustomerInfoIsValid = false;
  customerFormReportCoverInfoIsValid = false;

  loading = false;

  customerInfoFields: IEntityFormField[] = [
    {
      type: EntityFormFieldType.text,
      label: 'Name',
      propName: 'name',
      smCols: 4,
      required: true
    },
    {
      type: EntityFormFieldType.text,
      label: 'Branch',
      propName: 'branch',
      smCols: 4,
      required: true
    },
    {
      type: EntityFormFieldType.text,
      label: 'Primary contact name',
      propName: 'primarycontactname',
      smCols: 4,
      required: true
    },
    {
      type: EntityFormFieldType.text,
      label: 'Primary contact e-mail',
      propName: 'primarycontactemailaddress',
      smCols: 4,
      required: true
    }
  ];
  customerReportCoverFields: IEntityFormField[] = [
    {
      type: EntityFormFieldType.text,
      label: 'Street',
      propName: 'street',
      smCols: 4,
      required: true
    },
    {
      type: EntityFormFieldType.text,
      label: 'City',
      propName: 'city',
      smCols: 4,
      required: true
    },
    {
      type: EntityFormFieldType.text,
      label: 'Telephone',
      propName: 'telephone',
      smCols: 4,
      required: true
    },
    {
      type: EntityFormFieldType.text,
      label: 'E-Mail',
      propName: 'emailaddress',
      smCols: 4,
      required: true
    },
    {
      type: EntityFormFieldType.text,
      label: 'Website',
      propName: 'website',
      smCols: 4,
      required: true
    }
  ];

  mounted() {
    this.customerAppDirtyUtil.init(this.internalCustomerValue);
  }

  async createCustomer() {
    try {
      if (
        this.customerFormCustomerInfoIsValid &&
        this.customerFormReportCoverInfoIsValid
      ) {
        const customerResult = await customersApi.createCustomer(
          this.internalCustomerValue
        );
        this.customerAppDirtyUtil.init(this.internalCustomerValue);
        await this.$router.push('/customers/' + customerResult.id);
      }
    } catch (ex) {
      console.log(ex);
      return null;
    }
  }

  @Watch('internalCustomerValue', { deep: true })
  onInternalCustomerValueChanged() {
    this.customerAppDirtyUtil.update(this.internalCustomerValue);
  }
}
