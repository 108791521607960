
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import {
  EntityFormFieldType,
  IEntityFormField,
  IKeyValuePair
} from '@/models/core';
import { IRecipient } from '@/models/application/IRecipient';
import { customersApi } from '@/apis';
import { visibleLanguages } from '@/lang';

@Component({
  components: {
    OsSheetContent,
    OsButton,
    OsInputTextField,
    OsSheet,
    OsEntityForm
  }
})
export default class OsCreateRecipientForm extends Vue {
  @Prop({ required: true })
  customerId!: string;
  @Prop({ required: true })
  campaignId!: string;
  @Prop({ default: 'create recipient' })
  textCreateRecipientButton!: string;
  @Prop({ default: 'E-Mail-Address' })
  textEmailAddress!: string;
  @Prop({ default: 'Language' })
  textLanguagecode!: string;

  @Prop({
    default: () =>
      visibleLanguages.map((l) => {
        return { key: l.name, value: l.code };
      })
  })
  languages!: IKeyValuePair<string, string | null>[];

  $refs!: {
    osEntityForm: OsEntityForm;
  };

  internalValue: IRecipient = {
    id: '',
    targetObjectId: '',
    emailaddress: '',
    languagecode: '',
    type: null
  };
  formIsValid = false;
  loading = false;

  get fields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.email,
        label: this.textEmailAddress,
        propName: 'emailaddress',
        smCols: 6,
        required: true
      },
      {
        type: EntityFormFieldType.select,
        label: this.textLanguagecode,
        propName: 'languagecode',
        smCols: 6,
        required: true,
        items: this.languages
      }
    ];
  }

  reset() {
    if (this.$refs.osEntityForm) {
      this.$refs.osEntityForm.reset();
    }
  }

  async onSubmit() {
    if (this.formIsValid) {
      this.loading = true;
      try {
        const result = await customersApi.createCampaignRecipient(
          this.customerId,
          this.campaignId,
          {
            emailaddress: this.internalValue.emailaddress,
            languagecode: this.internalValue.languagecode
          }
        );
        this.reset();
        this.$emit('created', result);
      } finally {
        this.loading = false;
      }
    }
  }
}
