
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsAntesDisc from '@/components/chart/OsAntesDisc.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsAntesDiscDummy from '@/components/chart/OsAntesDiscDummy.vue';
import OsParticipantForm from '@/components/shared/participants/OsParticipantForm.vue';
import {
  IParticipant,
  ISetScoreRequest,
  ISurveyCustomField,
  ISurveyCustomFieldAnswer
} from '@/models/application';
import { customersApi, participantsApi } from '@/apis';
import _ from 'lodash';
import OsButton from '@/components/core/OsButton.vue';
import OsParticipantPdfDownloadBtn from '@/components/shared/participants/OsParticipantPdfDownloadBtn.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsParticipantCustomFieldAnswersForm from '@/components/shared/participants/OsParticipantCustomFieldAnswersForm.vue';
@Component({
  components: {
    OsParticipantCustomFieldAnswersForm,
    OsInputTextField,
    OsLoadingIndicator,
    OsParticipantPdfDownloadBtn,
    OsButton,
    OsParticipantForm,
    OsAntesDiscDummy,
    OsSheetContent,
    OsSheet,
    OsAntesDisc
  }
})
export default class CampaignParticipantById extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();
  participant: IParticipant | null = null;

  loading = false;
  customerId!: string;

  @Prop({ default: 'The values explained:' })
  textDiscInformationHeader!: string;

  @Prop({ default: 'the dominant type' })
  textDiscInformationDTitle!: string;

  @Prop({
    default:
      'Individuals with a high D can be described as very ambitious, results and success oriented.'
  })
  textDiscInformationDText!: string;

  @Prop({ default: 'the initiative type' })
  textDiscInformationITitle!: string;

  @Prop({
    default:
      'People with a high I are characterized as extraverted, communicative and emotional people.'
  })
  textDiscInformationIText!: string;

  @Prop({ default: 'he steady/stable type' })
  textDiscInformationSTitle!: string;

  @Prop({
    default:
      'People with a high S level are characterized as very reliable, helpful and trustworthy people.'
  })
  textDiscInformationSText!: string;

  @Prop({ default: 'the conscientious type' })
  textDiscInformationCTitle!: string;

  @Prop({
    default:
      'People with a high C can be described as very correct, polite and diplomatic.'
  })
  textDiscInformationCText!: string;

  hadParticipantWsValues = false;

  customFieldAnswers: ISurveyCustomFieldAnswer[] = [];
  customFields: ISurveyCustomField[] = [];

  created() {
    this.customerId = this.$route.params.id;
  }

  async mounted() {
    const participantId = this.$route.params.participantId;

    this.customFields = await customersApi.getCustomFields(this.customerId);

    if (this.customFields && this.customFields.length > 0) {
      this.customFieldAnswers =
        await customersApi.getParticipantCustomFieldAnswersById(
          this.customerId,
          participantId
        );
    }

    this.participant = await customersApi.getParticipantById(
      this.customerId,
      participantId
    );

    this.hadParticipantWsValues =
      this.participant != null &&
      this.participant.dValueWs != null &&
      this.participant.iValueWs != null &&
      this.participant.sValueWs != null &&
      this.participant.cValueWs != null &&
      (this.participant.dValueWs > 0 ||
        this.participant.iValueWs > 0 ||
        this.participant.sValueWs > 0 ||
        this.participant.cValueWs > 0);
  }

  async onDelete() {
    if (
      await this.$global.dialogs.confirm(
        'Confirm deletion',
        `Are you sure you want to delete the participant ${
          this.participant!.emailaddress
        }?`
      )
    ) {
      const result = await customersApi.deleteParticipantById(
        this.customerId,
        this.participant!.id
      );
      if (result) {
        await this.$router.push(
          `/customers/${this.customerId}/campaigns/${
            this.participant!.campaignId
          }/participants`
        );
      }
    }
  }

  async onSave() {
    this.loading = true;
    try {
      const originDiscValues = (({
        dValue,
        iValue,
        sValue,
        cValue,
        dValueWs,
        iValueWs,
        sValueWs,
        cValueWs
      }) => ({
        dValue,
        iValue,
        sValue,
        cValue,
        dValueWs,
        iValueWs,
        sValueWs,
        cValueWs
      }))(this.appDirtyUtil.getOriginalObject()[0]);

      const currentDiscValues = (({
        dValue,
        iValue,
        sValue,
        cValue,
        dValueWs,
        iValueWs,
        sValueWs,
        cValueWs
      }) => ({
        dValue,
        iValue,
        sValue,
        cValue,
        dValueWs,
        iValueWs,
        sValueWs,
        cValueWs
      }))(this.participant!);

      const hasDiscValueChanges = !_.isEqual(
        originDiscValues,
        currentDiscValues
      );

      if (hasDiscValueChanges) {
        await customersApi.setScore(
          this.customerId,
          this.participant!.id,
          currentDiscValues as ISetScoreRequest
        );
      }

      this.participant = await participantsApi.updateParticipant(
        this.participant!
      );

      if (this.customFieldAnswers && this.customFieldAnswers.length > 0) {
        this.customFieldAnswers =
          await customersApi.setParticipantCustomFieldAnswersById(
            this.customerId,
            this.participant.id,
            this.customFieldAnswers
          );
      }

      this.appDirtyUtil.init([this.participant, this.customFieldAnswers]);
    } finally {
      this.loading = false;
    }
  }

  undoClicked() {
    this.participant = this.appDirtyUtil.getOriginalObject()[0];
    this.customFieldAnswers = this.appDirtyUtil.getOriginalObject()[1];
    this.appDirtyUtil.init([this.participant, this.customFieldAnswers]);
  }
}
