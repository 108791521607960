
import { CustomerState } from '@/models/application';
import { Component, PropSync, Watch } from 'vue-property-decorator';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import Vue from 'vue';

@Component({
  components: { OsAlertBox }
})
export default class OsCustomerInactiveBanner extends Vue {
  @PropSync('customerState')
  internalCustomerState!: CustomerState;

  @Watch('$store.state.app.selectedCustomerState')
  customerIsNotActive(): boolean {
    const customerState = this.$store.state.app.selectedCustomerState;
    if (!customerState) {
      return false;
    }
    return customerState !== CustomerState.Active;
  }
}
