
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import {
  AppuserState,
  ICustomer,
  IExtendedAppuser
} from '@/models/application';
import { GuidUtil } from '@/utils/GuidUtil';
import OsAppuserForm from '@/components/shared/appusers/OsAppuserForm.vue';
import { appuserApi, customersApi } from '@/apis';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsAlertBox from '@/components/core/OsAlertBox.vue';

@Component({
  components: { OsAlertBox, OsAppuserForm, OsSheetContent, OsSheet }
})
export default class CustomersAppUsersCreate extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  appuser: IExtendedAppuser = {
    id: GuidUtil.empty(),
    customerId: GuidUtil.empty(),
    emailaddress: '',
    firstname: '',
    lastname: '',
    state: AppuserState.Active,
    languagecode: null,
    isAdmin: false
  };
  customer: ICustomer | null = null;

  loading = false;
  customerId!: string;

  async created() {
    this.customerId = this.$route.params.id;
    this.customer = await customersApi.getCustomerById(this.customerId);
  }

  async onSave() {
    this.loading = true;
    try {
      this.appuser.customerId = this.customerId;
      const createdAppUser = await customersApi.createAppuser(
        this.customerId,
        this.appuser
      );

      if (this.appuser?.isAdmin) {
        await appuserApi.setIsAdmin(createdAppUser.id, true);
      }

      this.appDirtyUtil.init(this.appuser);
      await this.$router.push(`/customers/${this.customerId}/appusers/`);
    } finally {
      this.loading = false;
    }
  }
}
