
import { Component, VModel, Vue } from 'vue-property-decorator';
import { IReportContent } from '@/models/application/IReportContent';
import OsInputTextArea from '@/components/core/input/OsInputTextArea.vue';
@Component({
  components: { OsInputTextArea }
})
export default class OsReportContentEditor extends Vue {
  @VModel({ type: Array, required: true })
  contents!: IReportContent[];

  get contentsOrdered(): IReportContent[] {
    return this.contents.sort((c1, c2) =>
      c1.languagecode.localeCompare(c2.languagecode)
    );
  }

  tab: number | null = 0;
}
