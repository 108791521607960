import { BaseApi } from '@/apis/BaseApi';
import { IGeneralStatisticData } from '@/models/application/IGeneralStatisticData';
import { IKeyValuePair } from '@/models/core';

export class StatisticsApi extends BaseApi {
  constructor() {
    super('statistics');
  }

  async getGeneralStatistics(): Promise<IGeneralStatisticData> {
    return (await this.get<IGeneralStatisticData>('/generalstatistics')).result;
  }

  async getParticipantsPerDay(): Promise<IKeyValuePair<string, number>[]> {
    return (
      await this.get<IKeyValuePair<string, number>[]>('/participantsperday')
    ).result;
  }

  async getParticipantsPerCustomer(): Promise<IKeyValuePair<string, number>[]> {
    return (
      await this.get<IKeyValuePair<string, number>[]>(
        '/participantspercustomer'
      )
    ).result;
  }

  async getParticipantsPerLanguage(): Promise<IKeyValuePair<string, number>[]> {
    return (
      await this.get<IKeyValuePair<string, number>[]>(
        '/participantsperlanguage'
      )
    ).result;
  }

  async getDiscDistribution(): Promise<IKeyValuePair<string, number>[]> {
    return (
      await this.get<IKeyValuePair<string, number>[]>('/discdistribution')
    ).result;
  }
}
