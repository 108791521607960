
import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/app/DefaultLayout.vue';
import LoginLayout from '@/layouts/app/LoginLayout.vue';

@Component({
  components: { DefaultLayout, LoginLayout }
})
export default class App extends Vue {
  layout: string | null = null;

  mounted() {
    this.onRouteChanged();
  }

  @Watch('$route')
  onRouteChanged() {
    const path = this.$route.path;

    if (path.startsWith('/login') || path.startsWith('/setpassword')) {
      if (this.layout !== 'login-layout') {
        this.layout = 'login-layout';
      }
    } else {
      if (this.layout !== 'default-layout') {
        this.layout = 'default-layout';
      }
    }
  }
}
