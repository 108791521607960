
import { Component, PropSync, Vue } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { IReport } from '@/models/application/IReport';
import { ReportType } from '@/models/application';

@Component({
  components: { OsSheetContent, OsEntityForm, OsEntityToolbar }
})
export default class OsReportForm extends Vue {
  @PropSync('value', { required: true })
  internalValue!: IReport;

  @PropSync('appDirtyUtil', { required: true })
  internalAppDirtyUtil!: AppDirtyUtil;

  loading = false;

  get fields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.text,
        label: 'Name',
        propName: 'name',
        smCols: 6,
        required: true,
        readonly: true
      },
      {
        type: EntityFormFieldType.select,
        label: 'Type',
        propName: 'type',
        smCols: 6,
        required: true,
        readonly: true,
        items: [
          {
            // key: 'ist',
            key: 'Actual',
            value: ReportType.Actual
          },
          {
            // key: 'soll',
            key: 'Target',
            value: ReportType.Target
          }
        ]
      }
    ];
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  onSave() {
    this.$emit('onSave');
  }

  onUndo() {
    this.$emit('undoClicked');
  }
}
