
import { Component, Prop, Vue } from 'vue-property-decorator';
import { authenticationApi } from '@/apis';
import OsButton from '@/components/core/OsButton.vue';
import { defaultRoutes } from '@/router/default-routes';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
@Component({
  components: { OsInputTextField, OsSheet, OsAlertBox, OsButton }
})
export default class OsRequestPasswordResetForm extends Vue {
  @Prop({ default: 'Request password reset' })
  textTitle!: string;
  @Prop({ default: 'Please enter your email address' })
  textEnterEmail!: string;
  @Prop({ default: 'E-Mail-Address' })
  textEmailInputLabel!: string;
  @Prop({ default: 'Request password reset' })
  textRequestResetPasswordResetButton!: string;
  @Prop({ default: 'Back to login page' })
  textBackToLoginButton!: string;

  maxWidth = '450px';
  loading = false;
  info: string | null = null;
  email = '';

  async mounted() {
    const localAuthenticated = this.$store.getters['auth/isAuthenticated'];

    if (localAuthenticated) {
      const serverAuthenticated = await authenticationApi.checkAuthentication();

      if (serverAuthenticated) {
        await this.navigateToPostLoginPage();
      }
    }
  }

  async sendResetPasswordLink() {
    this.info = null;
    this.loading = true;

    try {
      console.log(this.email);
      await authenticationApi.requestPasswordReset(this.email);
      await this.$router.push('/login');
    } finally {
      this.loading = false;
    }
  }

  async navigateToPostLoginPage() {
    await this.$router.push(defaultRoutes.postLogin);
  }
}
