
import { Component, Prop, Vue } from 'vue-property-decorator';
import { filesApi } from '@/apis';
import OsButton from '@/components/core/OsButton.vue';
import { ConfigUtil } from '@/utils/ConfigUtil';
import moment from 'moment';
import _ from 'lodash';

@Component({
  components: { OsButton }
})
export default class OsLogoUploader extends Vue {
  @Prop({ required: true })
  languagecode!: string;

  @Prop({ default: 'Upload JSON file' })
  title!: string;

  file: Blob | null = null;

  loading = false;

  fileExists = false;

  created() {
    this.updateFileExists();
  }

  get fileUrl(): string {
    return `${ConfigUtil.get().translationsBaseUrl}/${this.languagecode}.json`;
  }

  async onFileChanged() {
    if (!this.file) {
      return;
    }

    if (this.file.type !== 'application/json') {
      this.showErrorToast('Unsupported file type. Supported types: .json');
      return;
    }

    if (this.file.size > 50000000) {
      this.showErrorToast('File is too large.');
      return;
    }

    this.loading = true;

    let formData = new FormData();
    formData.append('file', this.file);

    const success = await filesApi.uploadCustomerPortalTranslation(
      this.languagecode,
      formData
    );

    await this.updateFileExists();

    this.$emit('file-uploaded', success);

    this.file = null;
    this.loading = false;
  }

  showErrorToast(text: string) {
    this.$global.dialogs.toast(text, {
      color: 'error',
      icon: 'mdi-alert-circle-outline'
    });
  }

  async updateFileExists() {
    this.fileExists = await filesApi.fileExists(this.fileUrl);
  }

  async downloadFile() {
    let emptyTranslations = await filesApi.getFileContent(
      '/lang/customer_portal_empty.json'
    );

    let translation = await filesApi.getFileContent(this.fileUrl);
    let mergedTranslation = _.merge(emptyTranslations, translation);

    let translationAsString = JSON.stringify(mergedTranslation, null, 4);

    this.openDownloadDialog(
      translationAsString,
      `${this.languagecode.toUpperCase()}-${moment().format(
        'MM_DD_yy-hh-mm'
      )}.json`
    );
  }

  openDownloadDialog(content: string, filename: string) {
    let element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:application/plain;charset=utf-8,' + encodeURIComponent(content)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
