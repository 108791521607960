
import { Component, Vue } from 'vue-property-decorator';
import OsStatisticsCardGeneral from '@/components/chart/statistic/OsStatisticsCardGeneral.vue';
import OsStatisticsCardDiscDistribution from '@/components/chart/statistic/OsStatisticsCardDiscDistribution.vue';
import OsStatisticsCardParticipantsPerDay from '@/components/chart/statistic/OsStatisticsCardParticipantsPerDay.vue';
import OsStatisticsCardParticipantsPerCustomer from '@/components/chart/statistic/OsStatisticsCardParticipantsPerCustomer.vue';
import OsStatisticsCardParticipantsPerLanguage from '@/components/chart/statistic/OsStatisticsCardParticipantsPerLanguage.vue';

@Component({
  components: {
    OsStatisticsCardParticipantsPerLanguage,
    OsStatisticsCardParticipantsPerCustomer,
    OsStatisticsCardParticipantsPerDay,
    OsStatisticsCardDiscDistribution,
    OsStatisticsCardGeneral
  }
})
export default class StatisticsIndex extends Vue {
  cardsHeight = '300px';
}
