
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsInputRadioGroup from '@/components/core/input/OsInputRadioGroup.vue';
import { AdvancedRadioItem } from '@/models/core/AdvancedRadioItem';
import { DiscType } from '@/models/application/DiscType';

@Component({
  components: {
    OsInputRadioGroup,
    OsButton,
    OsInputTextField,
    OsSheet,
    OsEntityForm
  }
})
export default class OsCreateTargetProfileForm extends Vue {
  @Prop({ required: true })
  customerId!: string;

  @Prop({ required: true })
  campaignId!: string;

  @Prop({ required: true })
  profileName!: string;

  @Prop({ default: 'next...' })
  textButtonNext!: string;

  @Prop({
    default:
      'Please click on the block with the characteristics that are most important for the position.'
  })
  textSubHeader!: string;

  @Prop({
    default:
      'Dominant, direct, dynamic, driving, effective, proactive, competitive, strong, strategic, result-oriented, goal-oriented, assertive, willing to take risks, decisive, breaks new ground, unconventional, ambitious, good for discussion, courageous, self-confident, strong-willed, persistent, sets the direction , demanding, demanding, competitive, doer/implementer/reformer.'
  })
  textOptionDPositive!: string;

  @Prop({
    default:
      'Open, open to new things, positive, communicative, sociable, communicative, motivating, engaging, inspiring, full of ideas, solution-oriented, happy, committed, convincing, influencing, self-confident, confident, optimistic, emotional, impulsive, networker, people-oriented, brings people together, wins others over for himself and his ideas, ensures a pleasant, open atmosphere, generous, friendly, entertaining, popular.'
  })
  textOptionIPositive!: string;

  @Prop({
    default:
      'Stable, trustworthy, reliable, good listener, structured, orderly, service-oriented, goal-oriented, careful, careful, concentrated, level-headed, considered, loyal, follows standards and/or sets them up, copes well with routine activities, helpful, supportive, understanding, empathetic, patient, advisor/coach, protective, balanced, satisfied, rather calm and reserved, willing to compromise, relaxed, predictable, people-oriented, balancing, stabilizing, harmonious, friendly.'
  })
  textOptionSPositive!: string;

  @Prop({
    default:
      'Analytical, logical, factual and data-related, task-oriented, accurate, factual, critical and questioning, thorough, precise, organized, well prepared, detailed, very quality-conscious, strives for freedom from errors/perfection, is based on standards and specifications, follows rules , responsible, objective, diplomatic, controlled, disciplined, correct, adapting, consenting, polite.'
  })
  textOptionCPositive!: string;

  @Prop({
    default:
      'Diplomatic, meticulous, thoughtful, mindful, planned, orderly, patient, listens well, forgiving, empathetic, team and people oriented, gets enthusiastic about the ideas of others and is interested in their opinions/views, supportive, accommodating, tolerant, adaptable, self-critical, rather quiet and reserved, does not have to be in the front row, pleasant, friendly.'
  })
  textOptionDNegative!: string;

  @Prop({
    default:
      'Factual, logical, questioning, critical, considered, examining, organized, structured, concentrated, polite/formal, conservative/aligns itself with the tried and tested, controlled, rather calm and reserved, thoughtful, independent/gets along well on his own, discreet, controlled, Must be persuaded/skeptical, does not have to be the focus.'
  })
  textOptionINegative!: string;

  @Prop({
    default:
      'Active, lively, dynamic, impatient, energetic, open to change, imaginative, flexible, fast, driving, active, purposeful, determined, interested in many things, spontaneous, creative, breaks new ground, sets the direction, self-confident, independent, eager, ensures speed and implementation of ideas/processes.'
  })
  textOptionSNegative!: string;

  @Prop({
    default:
      'Unconventional, strong-willed, decisive, independent, delegated, straightforward, confident, direct, fast, extravagant, courageous, cheeky, assertive, stable, impulsive, adventurous, willing to take risks, spontaneous, flexible, easy-going, open-minded, trusting, confident, cooperative, open-minded and attentive to suggestions from others.'
  })
  textOptionCNegative!: string;

  loading = false;

  selectedValue: any = null;

  get discSelectionsPositive(): {
    label: string;
    type: DiscType;
  }[] {
    return [
      {
        label: this.textOptionDPositive,
        type: DiscType.D
      },
      {
        label: this.textOptionIPositive,
        type: DiscType.I
      },
      {
        label: this.textOptionSPositive,
        type: DiscType.S
      },
      {
        label: this.textOptionCPositive,
        type: DiscType.C
      }
    ];
  }

  get discSelectionsNegative(): {
    label: string;
    type: DiscType;
  }[] {
    return [
      {
        label: this.textOptionDNegative,
        type: DiscType.D
      },
      {
        label: this.textOptionINegative,
        type: DiscType.I
      },
      {
        label: this.textOptionSNegative,
        type: DiscType.S
      },
      {
        label: this.textOptionCNegative,
        type: DiscType.C
      }
    ];
  }

  selectedValues: { type: DiscType; value: number }[] = [];

  currentPageCount = 1;

  get pickableItems(): AdvancedRadioItem[] {
    if (this.currentPageCount === 1) {
      return this.discSelectionsPositive.map((v) => {
        return {
          key: v.label,
          value: `${v.type}|15`,
          itemClasses: ['py-3 black--text']
        };
      });
    } else if (this.currentPageCount === 2) {
      return this.discSelectionsNegative
        .filter((o) => o.type !== this.selectedValues[0].type)
        .map((v) => {
          return {
            key: v.label,
            value: `${v.type}|-15`,
            itemClasses: ['py-3 black--text']
          };
        });
    } else if (this.currentPageCount === 3) {
      return this.discSelectionsPositive
        .filter(
          (o) =>
            o.type !== this.selectedValues[0].type &&
            o.type !== this.selectedValues[1].type
        )
        .map((v) => {
          return {
            key: v.label,
            value: `${v.type}|10`,
            itemClasses: ['py-3 black--text']
          };
        });
    } else if (this.currentPageCount === 4) {
      const firstPositiveNotSelectedType = this.discSelectionsPositive.find(
        (o) => !this.selectedValues.find((sv) => sv.type === o.type)
      );

      const firstNegativeNotSelectedType = this.discSelectionsNegative.find(
        (o) => !this.selectedValues.find((sv) => sv.type === o.type)
      );

      if (firstPositiveNotSelectedType && firstNegativeNotSelectedType) {
        return [
          {
            key: firstPositiveNotSelectedType.label,
            value: `${firstPositiveNotSelectedType.type}|5`,
            itemClasses: ['py-3 black--text']
          },
          {
            key: firstNegativeNotSelectedType.label,
            value: `${firstNegativeNotSelectedType.type}|-5`,
            itemClasses: ['py-3 black--text']
          }
        ];
      }
    }

    return [];
  }

  get isValidForm() {
    return this.selectedValue;
  }

  async onSubmit() {
    if (!this.selectedValue) {
      return;
    }

    const type = this.selectedValue.split('|')[0] as DiscType;
    const value = parseFloat(this.selectedValue.split('|')[1]);

    this.selectedValues.push({ type, value });

    if (this.currentPageCount === 4) {
      if (this.isValidForm) {
        this.loading = true;
        try {
          this.$emit('click:save', {
            name: this.profileName,
            values: this.selectedValues
          });
        } finally {
          this.loading = false;
        }
      }
      return;
    }

    this.currentPageCount++;
    this.selectedValue = null;
  }

  reset() {
    this.currentPageCount = 1;
    this.selectedValues = [];
    this.selectedValue = null;
  }
}
