import { RouteConfig } from 'vue-router';
import SettingsIndex from '@/pages/settings/Index.vue';
import ReportsIndex from '@/pages/settings/reports/Index.vue';
import ReportsId from '@/pages/settings/reports/_id.vue';
import Reports from '@/pages/settings/Reports.vue';
import SettingsInternalUserIndex from '@/pages/settings/internalusers/Index.vue';
import InternalUsers from '@/pages/settings/InternalUsers.vue';
import SettingsInternalUserById from '@/pages/settings/internalusers/_id.vue';
import SettingsInternalUserCreate from '@/pages/settings/internalusers/Create.vue';
import Questionnaires from '@/pages/settings/Questionnaires.vue';
import SettingsQuestionnairesIndex from '@/pages/settings/questionnaires/Index.vue';
import SettingsQuestionnairesById from '@/pages/settings/questionnaires/_id.vue';
import Translations from '@/pages/settings/Translations.vue';
import TranslationsIndex from '@/pages/settings/translations/Index.vue';

const routeConfig: RouteConfig = {
  path: '/settings',
  component: SettingsIndex,
  children: [
    {
      path: 'internalusers',
      component: InternalUsers,
      children: [
        {
          path: '',
          component: SettingsInternalUserIndex
        },
        {
          path: 'create',
          component: SettingsInternalUserCreate
        },
        {
          path: ':id',
          component: SettingsInternalUserById
        }
      ]
    },
    {
      path: 'reports',
      component: Reports,
      children: [
        {
          path: '',
          component: ReportsIndex
        },
        {
          path: ':id',
          component: ReportsId
        }
      ]
    },
    {
      path: 'questionnaires',
      component: Questionnaires,
      children: [
        {
          path: '',
          component: SettingsQuestionnairesIndex
        },
        {
          path: ':id',
          component: SettingsQuestionnairesById
        }
      ]
    },
    {
      path: 'translations',
      component: Translations,
      children: [
        {
          path: '',
          component: TranslationsIndex
        }
      ]
    },
    {
      path: '',
      redirect: 'internalusers'
    }
  ]
};
export default routeConfig;
