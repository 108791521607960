
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class OsEntityToolbar extends Vue {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: false })
  isCreateForm!: boolean;

  @Prop({ default: false })
  isDirty!: boolean;

  @Prop({ default: 'transparent' })
  color!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  showCreateNew!: boolean;

  @PropSync('disabled', { default: false, required: false, type: Boolean })
  internalDisabled!: boolean;

  @PropSync('deleteAllowed', { default: true, required: false, type: Boolean })
  internalDeleteAllowed!: boolean;
}
