import { BaseApi } from '@/apis/BaseApi';
import { IReport } from '@/models/application/IReport';
import { IReportContent } from '@/models/application/IReportContent';
import {
  ICustomer,
  ICustomerSetting,
  IParticipant
} from '@/models/application';
import { ITargetProfile } from '@/models/application/ITargetProfile';

export class ReportsApi extends BaseApi {
  constructor() {
    super('reports');
  }

  async getReports(): Promise<IReport[]> {
    return (await this.get<IReport[]>()).result;
  }

  async getReportById(id: string): Promise<IReport> {
    return (await this.get<IReport>(`/${id}`)).result;
  }

  async getReportContentByReportId(
    reportId: string
  ): Promise<IReportContent[]> {
    return (await this.get<IReportContent[]>(`/${reportId}/content`)).result;
  }

  async updateReportContent(
    reportId: string,
    content: IReportContent
  ): Promise<IReportContent> {
    return (await this.put<IReportContent>(`/${reportId}/content`, content))
      .result;
  }

  async renderParticipantActualProfile(
    filename: string,
    customer: ICustomer,
    customerSetting: ICustomerSetting,
    participant: IParticipant,
    languagecode: string,
    textInThirdPerson: boolean
  ): Promise<boolean> {
    return await this.download(filename, `/participant-actual-profile/render`, {
      customer,
      customerSetting,
      participant,
      languagecode,
      textInThirdPerson
    });
  }

  async renderTargetProfile(
    filename: string,
    targetDiscType: string,
    jobName: string,
    languagecode: string,
    targetProfile: ITargetProfile | null = null
  ): Promise<boolean> {
    return await this.download(filename, `/target-profile/render`, {
      targetDiscType,
      jobName,
      languagecode,
      targetProfile
    });
  }

  async getTargetProfileContent(
    targetDiscType: string,
    languagecode: string
  ): Promise<IReportContent> {
    return (
      await this.get<IReportContent>(
        `/target-profile/content?targetDiscType=${targetDiscType}&languagecode=${languagecode}`
      )
    ).result;
  }
}
