
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsButton from '@/components/core/OsButton.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import { customerApiKeysApi } from '@/apis';
import { ICustomerApiKey } from '@/models/authentication/ICustomerApiKey';

@Component({
  components: { OsInputTextField, OsButton }
})
export default class OsCustomerApiKey extends Vue {
  customerId!: string;
  customerApiKey: ICustomerApiKey | null = null;
  internalCreateCustomerApiKeyLoading = false;
  internalDeleteCustomerApiKeyLoading = false;
  @Prop({ default: 'api-key copied to clipboard' })
  textApiKeyCopySuccess!: string;

  async created() {
    this.customerId = this.$route.params.id;

    this.customerApiKey = await customerApiKeysApi.getByCustomerIdAsync(
      this.customerId
    );
  }

  async copyCustomerApiKeyClicked() {
    if (this.customerApiKey?.apiKey) {
      const result = await this.$copyText(this.customerApiKey.apiKey);

      if (this.customerApiKey.apiKey === result.text) {
        this.$global.dialogs.toast(this.textApiKeyCopySuccess, {
          color: 'success',
          icon: 'mdi-content-copy'
        });
      }
    }
  }
  async createCustomerApiKeyClicked() {
    if (
      await this.$global.dialogs.confirm(
        'Confirm API Key creation',
        `Are you sure you want to create a API Key?`
      )
    ) {
      this.internalCreateCustomerApiKeyLoading = true;
      this.customerApiKey = await customerApiKeysApi.createByCustomerIdAsync(
        this.customerId
      );
      this.internalCreateCustomerApiKeyLoading = false;
    }
  }
  async refreshCustomerApiKeyClicked() {
    if (
      await this.$global.dialogs.confirm(
        'Confirm API Key refresh',
        `Are you sure you want to renew the API Key? The current API Key will no longer work.`
      )
    ) {
      this.internalCreateCustomerApiKeyLoading = true;
      this.customerApiKey = await customerApiKeysApi.createByCustomerIdAsync(
        this.customerId
      );
      this.internalCreateCustomerApiKeyLoading = false;
    }
  }

  async deleteCustomerApiKeyClicked() {
    if (
      await this.$global.dialogs.confirm(
        'Confirm API Key deletion',
        `Are you sure you want to delete the API Key? The API Key will no longer work.`
      )
    ) {
      this.internalDeleteCustomerApiKeyLoading = true;
      await customerApiKeysApi.deleteByCustomerId(this.customerId);
      this.customerApiKey = null;
      this.internalDeleteCustomerApiKeyLoading = false;
    }
  }
}
