
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import {
  ICustomerQuestionnaireSettings,
  ICustomerSetting
} from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsLogo from '@/components/core/OsLogo.vue';
import { Alignment } from '@/models/application/Alignment';
import OsCustomerApiKey from '@/components/core/OsCustomerApiKey.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import { supportedLanguages, visibleLanguages } from '@/lang';

@Component({
  components: {
    OsAlertBox,
    OsCustomerApiKey,
    OsLogo,
    OsSheetContent,
    OsEntityForm,
    OsEntityToolbar,
    OsToolbar
  }
})
export default class OsCustomerSettingsForm extends Vue {
  @PropSync('appDirtyUtil', { required: true })
  internalAppDirtyUtil!: AppDirtyUtil;

  @Prop({ required: true })
  customerId!: string;

  @PropSync('questionnaireSettings', { required: true })
  internalQuestionnaireSettings!: ICustomerQuestionnaireSettings;

  @PropSync('settings', { required: true })
  internalSettings!: ICustomerSetting;

  @Prop({ default: false, required: false })
  readonly!: boolean;

  @PropSync('loading', { default: false })
  internalLoading!: boolean;

  @Prop({ default: 'Settings' })
  textTitle!: string;

  @Prop({ default: 'What kind of links should be used?' })
  textLinksTitle!: string;
  @Prop({ default: 'Max global Links' })
  textMaxGlobalLinks!: string;
  @Prop({ default: 'Max individual Links' })
  textMaxIndividualLinks!: string;
  @Prop({ default: 'Max App users' })
  textMaxAppUsers!: string;

  @Prop({ default: 'Which questionnaire to use?' })
  textQuestionnaireTitle!: string;

  @Prop({ default: 'ANTES Profile Standard' })
  textShortQuestionnaire!: string;

  @Prop({ default: 'ANTES Profile Kombi' })
  textLongQuestionnaire!: string;

  @Prop({ default: 'Customer logo' })
  textCustomerLogoTitle!: string;
  @Prop({ default: 'Logo position' })
  textCustomerLogoPosition!: string;
  @Prop({ default: 'left' })
  textAlignmentLeft!: string;
  @Prop({ default: 'right' })
  textAlignmentRight!: string;
  @Prop({ default: 'Use customer Logo on reports' })
  textUseCustomerLogoOnReport!: string;
  @Prop({ default: 'Use customer Address on report cover' })
  textUseCustomerAddressOnReport!: string;
  @Prop({ default: 'Use customer Logo in E-Mail Templates' })
  textUseCustomerLogoOnEmailTemplate!: string;
  @Prop({ default: 'API Key' })
  textCustomerApiKeyTitle!: string;

  questionnaireFormIsValid = false;

  get questionnaireFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.checkbox,
        label: this.textShortQuestionnaire,
        propName: 'useShortQuestionnaire',
        smCols: 4,
        lgCols: 3
      },
      {
        type: EntityFormFieldType.checkbox,
        label: this.textLongQuestionnaire,
        propName: 'useLongQuestionnaire',
        smCols: 6
      }
    ];
  }

  settingsFormIsValid = false;

  get languageFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.select,
        label: 'Default language',
        propName: 'languagecode',
        smCols: 12,
        mdCols: 4,
        lgCols: 3,
        required: true,
        items: visibleLanguages.map((l) => {
          return { key: l.name, value: l.code };
        })
      },
      {
        type: EntityFormFieldType.number,
        label: 'Days until Participant Anonymization',
        propName: 'anonymizeParticipantsAfterDays',
        smCols: 12,
        mdCols: 8,
        lgCols: 6,
        required: false
      }
    ];
  }

  get linksFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.number,
        label: this.textMaxGlobalLinks,
        propName: 'maxGlobalLinkAmount',
        min: 0,
        max: 1000000000,
        smCols: 4,
        lgCols: 3
      },
      {
        type: EntityFormFieldType.number,
        label: this.textMaxIndividualLinks,
        propName: 'maxIndividualLinkAmount',
        min: 0,
        max: 1000000000,
        smCols: 4,
        lgCols: 3
      },
      {
        type: EntityFormFieldType.number,
        label: this.textMaxAppUsers,
        propName: 'maxAppuserAmount',
        min: 0,
        max: 1000000000,
        smCols: 4,
        lgCols: 3
      }
    ];
  }

  get logoFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.checkbox,
        label: 'Enable Company Logo',
        propName: 'logoEnabled',
        smCols: 12
      },
      {
        type: EntityFormFieldType.radio,
        label: this.textCustomerLogoPosition,
        propName: 'logoAlignment',
        visible: this.internalSettings.logoEnabled,
        smCols: 12,
        mdCols: 12,
        required: true,
        radioAsRow: true,
        items: [
          {
            key: this.textAlignmentLeft,
            value: Alignment.left
          },
          {
            key: this.textAlignmentRight,
            value: Alignment.right
          }
        ]
      },
      {
        type: EntityFormFieldType.checkbox,
        label: this.textUseCustomerLogoOnReport,
        propName: 'useBrandedReports',
        visible: this.internalSettings.logoEnabled,
        smCols: 12,
        mdCols: 4,
        lgCols: 3
      }
      // {
      //   type: EntityFormFieldType.checkbox,
      //   label: this.textUseCustomerLogoOnEmailTemplate,
      //   propName: 'useBrandedTemplates',
      //   visible: this.internalSettings.logoEnabled,
      //   smCols: 12,
      //   mdCols: 4,
      //   lgCols: 3
      // }
    ];
  }

  get otherFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.checkbox,
        label: this.textUseCustomerAddressOnReport,
        propName: 'useAddressInReports',
        smCols: 12,
        mdCols: 4,
        lgCols: 3
      }
    ];
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  onUndo() {
    this.$emit('undoClicked');
  }

  @Watch('internalQuestionnaireSettings', { deep: true })
  @Watch('internalSettings', { deep: true })
  onInternalValueChanged() {
    this.internalAppDirtyUtil.update({
      ...this.internalQuestionnaireSettings,
      ...this.internalSettings
    });

    if (!this.internalSettings.logoEnabled) {
      this.internalSettings.useBrandedReports = false;
      this.internalSettings.useBrandedTemplates = false;
    }
  }
}
