
import { Component, Prop, Vue } from 'vue-property-decorator';
import { customersApi } from '@/apis';
import OsButton from '@/components/core/OsButton.vue';
@Component({
  components: { OsButton }
})
export default class OsLogoUploader extends Vue {
  @Prop({ default: true })
  showDeleteButton!: boolean;

  file: Blob | null = null;

  loading = false;

  async onFileChanged() {
    if (!this.file) {
      return;
    }

    if (!'image/png, image/jpeg'.includes(this.file.type)) {
      this.showErrorToast(
        'Unsupported file type. Supported types: .png .jpg .jpeg'
      );
      return;
    }

    if (this.file.size > 5000000) {
      this.showErrorToast('File is too large.');
      return;
    }

    this.loading = true;

    let formData = new FormData();
    formData.append('file', this.file);

    const logoUrl = await customersApi.uploadCustomerLogo(
      this.$route.params.id,
      formData
    );

    this.$emit('logo-changed', logoUrl);
    this.loading = false;
  }

  showErrorToast(text: string) {
    this.$global.dialogs.toast(text, {
      color: 'error',
      icon: 'mdi-alert-circle-outline'
    });
  }

  async onDeleteClicked() {
    const result = await this.$global.dialogs.confirm(
      'Remove Logo',
      'Do you really want to remove the Logo?'
    );

    if (result) {
      await customersApi.deleteLogo(this.$route.params.id);
      this.file = null;
      this.$emit('logo-changed', null);
    }
  }
}
