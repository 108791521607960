
import { Component, Vue } from 'vue-property-decorator';
import OsParticipantOverview from '@/components/shared/participants/OsParticipantOverview.vue';
import { customersApi } from '@/apis';
import { IParticipant } from '@/models/application';
@Component({
  components: { OsParticipantOverview }
})
export default class CampaignParticipantsIndex extends Vue {
  customerId!: string;
  campaignId!: string;
  participants: IParticipant[] = [];

  async created() {
    this.customerId = this.$route.params.id;
    this.campaignId = this.$route.params.campaignid;
    this.participants = await customersApi.getCampaignParticipants(
      this.customerId,
      this.campaignId
    );
  }

  rowClicked(selected: any) {
    this.$router.push(
      `/customers/${this.customerId}/campaigns/${this.campaignId}/participants/${selected.id}`
    );
  }
}
