
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { AppuserState, IExtendedAppuser } from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';

@Component({
  components: { OsSheetContent, OsEntityForm, OsEntityToolbar }
})
export default class OsInternalUserForm extends Vue {
  @PropSync('appDirtyUtil', { required: true })
  internalAppDirtyUtil!: AppDirtyUtil;
  @Prop({ required: false, default: false })
  isCreateForm!: boolean;
  @PropSync('value', { required: true })
  internalValue!: IExtendedAppuser;

  @Prop({ default: 'Create new internal user' })
  titleCreateForm!: string;
  @Prop({ default: 'Internal user' })
  titleExistingForm!: string;
  @Prop({ default: '' })
  textState!: string;
  @Prop({ default: 'Active' })
  textAppuserStateActive!: string;
  @Prop({ default: 'Inactive' })
  textAppuserStateInactive!: string;
  @Prop({ default: 'Language' })
  textLanguagecode!: string;
  @Prop({ default: 'English' })
  textLanguagecodeOptionEn!: string;
  @Prop({ default: 'German' })
  textLanguagecodeOptionDe!: string;
  @Prop({ default: 'Dansk' })
  textLanguagecodeOptionDa!: string;
  @Prop({ default: 'Firstname' })
  textFirstname!: string;
  @Prop({ default: 'Lastname' })
  textLastname!: string;
  @Prop({ default: 'E-Mail-Address' })
  textEmailAddress!: string;

  loading = false;
  formIsValid = false;

  get fields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.radio,
        label: this.textState,
        propName: 'state',
        smCols: 6,
        required: true,
        radioAsRow: true,
        readonly: this.isCreateForm,
        items: [
          { key: this.textAppuserStateActive, value: AppuserState.Active },
          {
            key: this.textAppuserStateInactive,
            value: AppuserState.Inactive
          }
        ]
      },
      {
        type: EntityFormFieldType.checkbox,
        label: 'Administrator',
        propName: 'isAdmin',
        smCols: 6
      },
      {
        type: EntityFormFieldType.text,
        label: this.textFirstname,
        propName: 'firstname',
        smCols: 6,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textLastname,
        propName: 'lastname',
        smCols: 6,
        required: true
      },
      {
        type: EntityFormFieldType.email,
        label: this.textEmailAddress,
        propName: 'emailaddress',
        smCols: 12,
        required: true
      }
    ];
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  onSave() {
    this.$emit('onSave');
  }

  onDelete() {
    this.$emit('onDelete');
  }

  onUndo() {
    this.$emit('undoClicked');
  }

  openCreateNewForm() {
    this.$emit('openCreateNewForm');
  }

  mounted() {
    this.internalAppDirtyUtil.init(this.internalValue);
  }

  @Watch('internalValue', { deep: true })
  onInternalValueChanged() {
    this.internalAppDirtyUtil.update(this.internalValue);
  }
}
